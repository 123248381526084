import React, { useEffect, useState } from "react";
import TopBar from "components/layouts/TopBar";
import Button from "components/common/Button";
import useLoadingButton from "hooks/useLoadingButton";
import { BiPhotoAlbum } from "@react-icons/all-files/bi/BiPhotoAlbum";
import { uploadFile } from "api/FileApi";
import { withAsync } from "helpers/withAsync";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { editPublication, postPublication } from "api/PublicationApi";
import useToken from "hooks/useToken";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { CheckboxButton } from "../../../components/page/CategoryCheckbox";
import { CategorieList } from "../../../constants/PageCategory";
import { Avatar } from "../../../components/common/Avatar";

interface PublicationFormProps {
  _id?: string;
  content?: string;
  isNewPub: boolean;
  btnText: string;
}

const PublicationForm: React.FC<PublicationFormProps> = ({
  _id,
  content,
  isNewPub,
  btnText,
}) => {
  const { t } = useTranslation();
  const [files, setFile] = useState<any>();
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    t("publications.photo")
  );
  const [isLoading, startLoading, endLoading] = useLoadingButton();
  const [fileViewers, setFileViewers] = useState<string[]>([]);
  const [publicationText, setPublicationText] = useState<string>();
  const toastMessage = [t("toast.error"), t("toast.success")];
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const token = useToken();
  const navigate = useNavigate();

  const getCheckValue = (e: any, isChecked: boolean) => {
    const categoryType = e.target.dataset.type;
    if (!isChecked) {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        categoryType,
      ]);
    } else {
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((cat) => cat !== categoryType)
      );
    }
  };

  const { profile } = useAppSelector((state) => state.teratany_user);

  const handleFileChange = (e: any) => {
    setFileViewers([]);
    setSelectedPhoto(
      `${
        e.target.files.length > 1
          ? e.target.files.length + " images "
          : e.target.files.length + " image "
      }  selected`
    );
    for (let index = 0; index < e.target.files.length; index++) {
      setFileViewers((prevState) => [
        ...prevState,
        URL.createObjectURL(e.target.files[index]),
      ]);
    }

    setFile(e.target.files);
  };

  const uploadImageFile = async () => {
    if (files) {
      let formData = new FormData();
      for (let index = 0; index < files.length; index++) {
        formData.append("images", files[index]);
      }

      const { error, response } = await withAsync(() => uploadFile(formData));
      if (error instanceof AxiosError) {
        endLoading();
        const error_message: string =
          error?.response?.data.description ||
          error?.response?.data ||
          error.message;
        toast.error(error_message);
      } else {
        return response?.data;
      }
    }
  };

  const AddPost = async () => {
    startLoading();
    const images = await uploadImageFile();

    if (publicationText) {
      const { error } = await withAsync(() =>
        postPublication(
          token,
          profile?._id!,
          publicationText,
          selectedCategories,
          images
        )
      );

      if (error instanceof AxiosError) {
        endLoading();
        const error_message: string =
          error?.response?.data.description ||
          error?.response?.data ||
          error.message;
        toast.error(error_message);
      } else {
        endLoading();
        toast.success(toastMessage[1]);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } else {
      toast.error(toastMessage[0]);
      endLoading();
    }
  };

  const EditPost = async () => {
    startLoading();

    if (publicationText) {
      const { error } = await withAsync(() =>
        editPublication(token, _id!, publicationText, selectedCategories)
      );

      if (error instanceof AxiosError) {
        endLoading();
        const error_message: string =
          error?.response?.data.description ||
          error?.response?.data ||
          error.message;
        toast.error(error_message);
      } else {
        endLoading();
        toast.success(toastMessage[1]);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } else {
      toast.error(toastMessage[0]);
      endLoading();
    }
  };

  useEffect(() => {
    setPublicationText(content);
  }, [content]);

  let translatedCategories: { value: string; text: string }[] = [];

  // Boucler sur les deux index de CategorieList
  for (let i = 0; i < CategorieList.length; i++) {
    const categories = CategorieList[i];
    const translated = categories.map((category) => ({
      value: category.value,
      text: t(
        `settings.categories.${category.text.toLowerCase().replace(/\s/g, "")}`
      ),
    }));
    translatedCategories = [...translatedCategories, ...translated];
  }

  return (
    <div className="flex flex-col items-center w-full overflow-hidden">
      <div className="fixed z-40 py-2 border-b bg-white flex justify-center items-center w-full max-w-[500px]">
        <TopBar
          text={`${
            isNewPub ? t("publications.addPub") : t("publications.editPub")
          }`}
        />
      </div>
      <div className="flex flex-col items-center w-full max-w-[500px]">
        <div className="w-full flex justify-start items-center space-x-4 mt-16 ml-10">
          <Avatar src={profile?.image} />
          <p className="flex ">{profile?.name}</p>
        </div>
        <textarea
          className="w-[90%] m-4  max-w-[500px] bg-gray-50 border border-gray-300 rounded-lg focus:ring-black focus:border-black text-sm p-2"
          value={publicationText}
          onChange={(e) => setPublicationText(e.target.value)}
          placeholder={t("publications.publicationPlaceholder")}
        />

        {isNewPub && (
          <div className="flex justify-start items-center w-[90%] max-w-[500px] p-2">
            <label htmlFor="image" className="flex justify-start items-center">
              <BiPhotoAlbum size={30} />
              <p className="ml-2">{selectedPhoto}</p>
            </label>
            <input
              type="file"
              id="image"
              className="hidden  max-w-[500px]"
              onChange={handleFileChange}
              multiple
            />
          </div>
        )}

        <div className="w-[90%] flex overflow-y-auto justify-start max-w-[500px]">
          {fileViewers.map((file) => (
            <img
              loading="lazy"
              className="h-28 rounded-md mt-2 mr-2 object-cover  max-w-[500px]"
              src={file}
              alt="selectedPhoto"
            />
          ))}
        </div>
      </div>
      <div className="pl-3 py-2 mt-1 w-full max-w-[500px] max-h-[500px] overflow-y-hidden mr-2">
        <div className="flex flex-wrap justify-start w-full items-center  max-h-[40vh] overflow-y-scroll">
          {translatedCategories.map((category) => (
            <CheckboxButton
              value={category.value}
              onClick={getCheckValue}
              text={category.text}
            />
          ))}
        </div>
      </div>
      <div className=" max-w-[500px] fixed bottom-14 p-4 w-full">
        <Button
          className=" w-full"
          name={t("publications.post")}
          isLoading={isLoading}
          onClick={isNewPub ? AddPost : EditPost}
        />
      </div>
    </div>
  );
};

export default PublicationForm;
