import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import {
  cancelMenu,
  showAnswear,
  showEditMessageInput,
} from "../../store/reducer/chat.reducer";
import SocketContext from "../../services/socket/socketContext";

export const MessageMenu: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const message: { id: string; text: string; isMine: boolean } = useAppSelector(
    (state) => state.teratany_chat.editMessage
  );
  const conversationRef: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const conversations = useAppSelector(
    (state) => state.teratany_chat.discussions
  );
  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const actualDiscussion = conversations.find(
    (element: any) => element.reference === conversationReference
  );
  const connectedUser = useAppSelector((state) => state.teratany_user.id);

  const isShow: boolean = useAppSelector((state) => state.teratany_chat.isMenu);
  const dispatch = useDispatch();

  const hide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      dispatch(cancelMenu());
    }
  };

  const editMessage = () => {
    dispatch(showEditMessageInput());
  };
  const answearMessage = () => {
    dispatch(showAnswear({ id: message.id, message: message.text }));
  };

  const deleteEveryoneMessage = () => {
    if (socket) {
      socket.emit("delete-everyone-message", message.id, conversationRef);
      dispatch(cancelMenu());
    }
  };
  const deleteMeMessage = () => {
    if (socket) {
      socket.emit(
        "delete-me-message",
        message.id,
        conversationRef,
        connectedUser
      );
      dispatch(cancelMenu());
    }
  };
  if (!isShow) {
    return <></>;
  }

  return (
    <div
      onClick={hide}
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
    >
      <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
        <ul
          className="py-2 text-sm text-gray-700"
          aria-labelledby="dropdownHoverButton"
        >
          {message.isMine && (
            <li
              className="block px-4 py-2 hover:bg-gray-100"
              onClick={editMessage}
            >
              Edit
            </li>
          )}
          {!(
            actualDiscussion?.mode !== "duo" &&
            !actualDiscussion?.admins?.includes(connectedUser)
          ) && (
            <li
              className="block px-4 py-2 hover:bg-gray-100"
              onClick={answearMessage}
            >
              Reply
            </li>
          )}
          <li
            className="block px-4 py-2 hover:bg-gray-100"
            onClick={deleteMeMessage}
          >
            Delete for me
          </li>
          {message.isMine && (
            <li
              className="block px-4 py-2 hover:bg-gray-100 text-red-500"
              onClick={deleteEveryoneMessage}
            >
              Delete for everyone
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
