import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IMessage {
  _id: string;
  sender: IProfile;
  text: string;
  images?: Array<string>;
  audio?: string;
  conversation: number;
  date?: Date;
}

export interface IConversation {
  groupInfo?: any;
  _id: string;
  ownerId: string;
  reference: number;
  participants: Array<IProfile>;
  messages: Array<IMessage>;
  overview: string;
  lastMessageDate: Date;
  newMessageCount: number;
  mode: string; //duo ou group ou canal
  admins: Array<string>;
  owner: string;
}

interface IToggleMessageMenu {
  message: string;
  id: string;
  isMine: boolean;
}
interface IToggleMessageAnswear {
  message: string;
  id: string;
}

export interface IProfile {
  _id: string;
  name: string;
  image: string;
  categories: string[];
  profileType: string;
  role: number;
}

export interface IDiscussion {
  _id: string;
  reference: number;
  lastMessageDate: string | Date;
  newMessageCount: number;
  admins: string[];
  mode: string;
}
export interface chatInitialState {
  discussions: any;
  activeDiscussionReference: number;
  loading: boolean;
  error: string;
  conversationType: string;
  isMenu: boolean;
  isEditMessage: boolean;
  editMessage: {
    id: string;
    text: string;
    isMine: boolean;
  };
  isAnswearMessage: boolean;
  answearMessage: {
    id: string;
    text: string;
  };
}

const initialState: chatInitialState = {
  discussions: [],
  loading: false,
  error: "",
  activeDiscussionReference: 0,
  conversationType: "duo",
  isMenu: false,
  isEditMessage: false,
  editMessage: {
    id: "",
    text: "",
    isMine: false,
  },
  isAnswearMessage: false,
  answearMessage: {
    id: "",
    text: "",
  },
};

export const chatSlice = createSlice({
  name: "teratany_chat",
  initialState,
  reducers: {
    showMenu: (state, action: PayloadAction<IToggleMessageMenu>) => {
      state.isMenu = true;
      state.editMessage.id = action.payload.id;
      state.editMessage.text = action.payload.message;
      state.editMessage.isMine = action.payload.isMine;
    },
    cancelMenu: (state, action: PayloadAction<void>) => {
      state.isMenu = false;
      state.editMessage.id = "";
      state.editMessage.text = "";
    },
    showEditMessageInput: (state) => {
      state.isEditMessage = true;
      state.isMenu = false;
    },

    editMessage: (state, action: PayloadAction<string>) => {
      state.editMessage.text = action.payload;
    },
    /* eslint-disable */
    resetNewMessageCount: (state, action: PayloadAction<number>) => {
      state.discussions = state.discussions.map((discussion: IDiscussion) => {
        if (discussion.reference === action.payload) {
          discussion._id = discussion._id;
          discussion.reference = discussion.reference;
          discussion.lastMessageDate = discussion.lastMessageDate;
          discussion.newMessageCount = 0;
          discussion.admins = discussion.admins;
          discussion.mode = discussion.mode;
        }
        return discussion
      });
    },
    /* eslint-disable */
    hideEditMessageInput: (state) => {
      state.isEditMessage = false;
      state.editMessage.id = "";
      state.editMessage.text = "";
    },

    showAnswear: (state, action: PayloadAction<IToggleMessageAnswear>) => {
      state.isAnswearMessage = true;
      state.answearMessage.id = action.payload.id;
      state.answearMessage.text = action.payload.message;
    },
    cancelAnswear: (state) => {
      state.isAnswearMessage = false;
      state.isEditMessage = false;
      state.isMenu = false;
      state.answearMessage.id = "";
      state.answearMessage.text = "";
    },
    showAnswearMessageInput: (state) => {
      state.isAnswearMessage = true;
    },
    hideAnswearMessageInput: (state) => {
      state.isAnswearMessage = false;
    },

    syncChat: (state, action: PayloadAction<any>) => {
      state.discussions = action.payload;
      for (let i = 0; i < state.discussions.length; i++) {
        state.discussions[i].messages = state.discussions[i]?.messages.sort(
          (a: any, b: any) => a.date - b.date
        );
      }
    },
    openDiscussion: (state, action: PayloadAction<number>) => {
      state.activeDiscussionReference = action.payload;
    },
    switchConversationType: (state, action: PayloadAction<string>) => {
      state.conversationType = action.payload;
    },
  },
});

export const {
  syncChat,
  openDiscussion,
  switchConversationType,
  showMenu,
  cancelMenu,
  showEditMessageInput,
  hideEditMessageInput,
  showAnswear,
  cancelAnswear,
  showAnswearMessageInput,
  hideAnswearMessageInput,
  editMessage,
  resetNewMessageCount
} = chatSlice.actions;
export default chatSlice.reducer;
