import { Link } from "react-router-dom";
import FeedNoPage from "../NoPage/FeedNoPage";
import { useAppSelector } from "../../store/hooks";
import { Avatar } from "../../components/common/Avatar";

const PageTopList = () => {
  const profileFollowed = useAppSelector(
    (state) => state.teratany_profiles.followed_profiles
  );

  return (
    <>
      <>
        {profileFollowed?.length! > 0 ? (
          <div className="flex my-2 overflow-x-auto no-scrollbar w-full max-w-[600px]  pt-2 pb-0 pl-4 mt-10">
            {profileFollowed?.map((profile, index) => (
              <div className="flex flex-col items-center mr-4" key={index}>
                <Link to={`/profile/${profile?._id}`}>
                  <Avatar
                    className="w-16 h-16  min-w-[4rem]  min-h-[4rem]"
                    src={profile?.image}
                  />
                </Link>
                <p className="truncate text-center">{profile?.name}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[calc(100vh-8rem)]">
            <FeedNoPage />
          </div>
        )}
      </>
    </>
  );
};

export default PageTopList;
