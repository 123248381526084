import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../shadcn/AlertDialog";
import { BiSolidExit } from "react-icons/bi";
import SocketContext from "../../services/socket/socketContext";
import { useDispatch } from "react-redux";
import { syncChat } from "../../store/reducer/chat.reducer";
import { syncChat as syncChatApi } from "../../api/chatApi";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useAppSelector } from "../../store/hooks";

export const LeaveGroup: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const connectedUser = useAppSelector((state) => state.teratany_user.id);
  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handdleQuit = () => {
    if (socket) {
      socket.emit(
        "quit-channel",
        connectedUser,
        conversationReference,
        async () => {
          navigate("/");
          dispatch(syncChat(await syncChatApi(connectedUser!, [], undefined)));
        }
      );
    }
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <BiSolidExit size={23} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to leave this increadible group
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handdleQuit}>Leave</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
