const CategorieList = [
    [
        {
            value: "OTHER",
            text: "Other",
        },
        {
            value: "ODD1",
            text: "No poverty",
        },
        {
            value: "ODD2",
            text: "Zero hunger",
        },
        {
            value: "ODD3",
            text: "Good health and well-being",
        },
        {
            value: "ODD4",
            text: "Quality education",
        },
        {
            value: "ODD5",
            text: "Gender equality",
        },
        {
            value: "ODD6",
            text: "Clear water and sanitation",
        },
        {
            value: "ODD7",
            text: "Affordable and clean energy",
        },
        {
            value: "ODD8",
            text: "Decent work and growth",
        },
        {
            value: "ODD9",
            text: "Industry, innovation and infrastructure",
        },
        {
            value: "ODD10",
            text: "Reduce inequalities",
        },
        {
            value: "ODD11",
            text: "Sustainable cities and communities",
        },
        {
            value: "ODD12",
            text: "Responsable consumption and production",
        },
        {
            value: "ODD13",
            text: "Climate action",
        },
        {
            value: "ODD14",
            text: "Life below water",
        },
        {
            value: "ODD15",
            text: "Life on land",
        },
        {
            value: "ODD16",
            text: "Peace, justice",
        },
        {
            value: "ODD17",
            text: "Partenerships for other goals",
        },
    ],
    [
        {
            value: "RSE1",
            text: "Innovative",
        },
        {
            value: "RSE2",
            text: "Attractive",
        },
        {
            value: "RSE3",
            text: "Develops short circuits and responsible purchasing",
        },
        {
            value: "RSE4",
            text: "Promotes employment in the region",
        },
        {
            value: "RSE5",
            text: "Develop its competitiveness",
        },
        {
            value: "RSE6",
            text: "Respects the environment and biodiversity",
        },
        {
            value: "RSE7",
            text: "Promotes equality and diversity",
        },
        {
            value: "RSE8",
            text: "Promotes safety and well-being at work",
        },
        {
            value: "RSE9",
            text: "Contributes to the energy transition",
        },
        {
            value: "RSE10",
            text: "Promotes the development of the circular economy",
        },
        {
            value: "RSE11",
            text: "Fight against corruption",
        },
        {
            value: "RSE12",
            text: "Open to dialogue and collaborative",
        },
        {
            value: "RSE13",
            text: "Develop culture and risk management",
        },
    ],
];

export { CategorieList }