import { Field } from "formik";

const FormField = (props: {
  type: string;
  value?: string;
  label: string;
  className?: string;
  onChange?: any;
  mark?: string;
}) => {
  return (
    <>
      <div className="relative">
        <Field
          type={props.type}
          name={props.mark}
          className={`flex w-full rounded-md border border-input bg-transparent px-3 py-3 mt-2 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 ${
            props.className ? props.className : ""
          }`}
          aria-describedby="email-error"
          value={props.value}
          placeholder={props.label}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default FormField;
