import React from "react";
import SearchBar from "components/common/SearchBar";
import SearchFilterBar from "components/common/SearchFilterBar";
import { HiArrowNarrowLeft } from "react-icons/hi";
import Publication from "components/publication/Publication";
import HorizontalCards from "components/common/HorizontalCards";
import { useNavigate, useParams } from "react-router-dom";
import useFetchSearchByQuery from "hooks/useFetchSearchByQuery";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";

const SearchResult: React.FC = () => {
  const { query } = useParams();

  const results = useFetchSearchByQuery(query!);

  const { profile } = useAppSelector((state) => state.teratany_user);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    window.history.go(-1);
  };

  return (
    <div className="flex flex-col items-center w-full overflow-hidden">
      <div className="fixed z-40 bg-white mx-3 mb-4 pt-4 flex flex-col justify-center w-full max-w-[600px]">
        <div className="flex items-center">
          <HiArrowNarrowLeft
            size={30}
            className="mr-4 ml-2"
            onClick={handleGoBack}
          />
          <SearchBar />
        </div>
        <SearchFilterBar query={query} />
      </div>
      {results?.profiles?.length! > 0 && (
        <div className="w-full border-b border-b-1 max-w-[600px]">
          <div className="mt-24 flex w-full flex-col pb-3 items-start justify-center">
            <p className="mx-3 mt-2  ">{t("users.plural")}</p>
            <>
              {results?.profiles?.slice(0, 3).map((user) => {
                const isButtonShowed = profile?._id !== user._id;
                return (
                  <HorizontalCards
                    _id={user._id}
                    name={user.name}
                    image={user.image!}
                    isFollowed={user?.isFollowed}
                    desc={
                      user.numberOfFollowers > 1
                        ? t("followers.number.plural", {
                            followers: user?.numberOfFollowers,
                          })
                        : t("followers.number.singular", {
                            followers: user?.numberOfFollowers,
                          })
                    }
                    isButtonShowed={isButtonShowed}
                  />
                );
              })}
            </>
          </div>
          <div
            className="flex justify-center items-center py-2 cursor-pointer"
            onClick={() => {
              navigate(`/search/result/user/${query}`);
            }}
          >
            <p className="mr-2 text-md">{t("voirTout")}</p>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      )}
      {results?.publications?.length! > 0 && (
        <div className="w-full border-b border-b-1 max-w-[600px]">
          <div className="flex w-full flex-col pb-3 items-start justify-center">
            <p className="mx-3 mt-3 w-full text-start">
              {t("publications.plural")}
            </p>
            <div className=" w-full max-w-[600px]">
              {results?.publications?.slice(0, 5).map((pub) => (
                <Publication
                  key={pub?._id}
                  _id={pub?._id}
                  profileId={pub?.profile?._id}
                  profileName={pub?.profile?.name}
                  profileImage={pub?.profile?.image}
                  date={pub?.date}
                  comments={pub?.numberOfComments}
                  reactions={pub?.numberOfReactions}
                  content={pub?.content}
                  images={pub?.images!}
                  isReacted={pub.isReacted}
                  isShare={pub.isShare}
                  ownerId={pub?.owner?._id}
                  ownerName={pub?.owner?.name}
                  originalDate={pub?.originalDate}
                  originalId={pub?.originalId}
                  ownerImage={pub?.owner?.image}
                />
              ))}
            </div>
          </div>
          <div
            className="flex justify-center items-center py-2 cursor-pointer mb-2"
            onClick={() => {
              navigate(`/search/result/publication/${query}`);
            }}
          >
            <p className="mr-2 text-md">{t("voirTout")}</p>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchResult;
