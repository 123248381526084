import React from "react";
import { useNavigate } from "react-router-dom";
interface EditTypeProps {
  name: string;
  path?: string;
  type?: string;
  icon: JSX.Element;
}

const EditType: React.FC<EditTypeProps> = ({ name, path, type, icon }) => {
  const navigate = useNavigate();

  return (
    <li
      className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100"
      onClick={() => navigate(path as string)}
    >
      {icon}
      {name}
    </li>
  );
};

export default EditType;
