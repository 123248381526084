import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./store/hooks";
import { syncChat } from "./store/reducer/chat.reducer";
import { IProfile } from "./types/profile.type";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { store, persistor } from "store/store";
import { PersistGate } from "redux-persist/integration/react";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import * as process from "process";
import "./index.css";
// import useCallUser from "hooks/useCallUser";
// import VideoChat from "views/videoChat/components/VideoChat";
import ToastNotification from "components/common/ToastNotification";
import SocketContext from "./services/socket/socketContext";
import SocketProvider from "./services/socket/socketProvider";
import { syncChat as syncChatApi } from "./api/chatApi";
import {
  addListeners,
  getDeliveredNotifications,
  registerNotifications,
} from "./services/fcm";

(window as any).global = window;
(window as any).process = process;
(window as any).Buffer = [];

addListeners();
registerNotifications();
getDeliveredNotifications();

// Désactive le traitement passif pour tous les événements tactiles
document.addEventListener("touchstart", function () {}, { passive: false });
document.addEventListener("touchmove", function () {}, { passive: false });
document.addEventListener("touchend", function () {}, { passive: false });
document.addEventListener("touchcancel", function () {}, { passive: false });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const MainView: React.FC = () => {
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.teratany_user);
  const { socket } = useContext(SocketContext);
  // const { isIncommingCall, answerCall, rejectCall } = useCallUser();

  const syncChatCaller = async (
    profileId: string,
    conversationReferences: number[],
    fromDate: Date | undefined
  ) => {
    dispatch(
      syncChat(await syncChatApi(profileId, conversationReferences, fromDate))
    );
  };

  const connection = (profileConnectedUser: IProfile) => {
    if (socket) {
      syncChatCaller(
        profileConnectedUser._id || "",
        profileConnectedUser.conversations || [],
        undefined
      );
      socket.on("connect", () => {});
      socket.emit("connect-profile", profileConnectedUser._id);
      socket.on("new-message", (message: any) => {
        syncChatCaller(
          profileConnectedUser._id || "",
          profileConnectedUser.conversations || [],
          undefined
        );
      });

      socket.on("delete-everyone-message", () => {
        syncChatCaller(
          profileConnectedUser._id || "",
          profileConnectedUser.conversations || [],
          undefined
        );
      });
      socket.on("added-to-group", () => {
        syncChatCaller(
          profileConnectedUser._id || "",
          profileConnectedUser.conversations || [],
          undefined
        );
      });
      socket.on("delete-me-message", () => {
        syncChatCaller(
          profileConnectedUser._id || "",
          profileConnectedUser.conversations || [],
          undefined
        );
      });

      socket.on("edit-message", () => {
        syncChatCaller(
          profileConnectedUser._id || "",
          profileConnectedUser.conversations || [],
          undefined
        );
      });
    }
  };

  useEffect(() => {
    if (profile && socket) {
      connection(profile);
      socket.on("disconnect", () => {
        connection(profile);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflow-x-hidden">
      {/* {isIncommingCall.showCall && (
        <VideoChat
          caller={isIncommingCall.caller}
          answerCall={answerCall}
          rejectCall={rejectCall}
        />
      )} */}
      <RouterProvider router={router} />
    </div>
  );
};

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastNotification />
        <SocketProvider>
          <MainView />
        </SocketProvider>
      </PersistGate>
    </Provider>
  </I18nextProvider>
);
