import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { switchFeedType } from "../../store/reducer/publication.reducer";
import { useTranslation } from "react-i18next";

const PublicationSwitcher: React.FC = () => {
  const { feedType: pubType } = useAppSelector(
    (state) => state.teratany_publications
  );
  const dispatch = useDispatch();
  const switchFeed = (type: string) => {
    dispatch(switchFeedType(type));
  };
  const { t } = useTranslation();
  return (
    <div className="fixed z-50 top-[3rem] flex items-center justify-around w-full max-w-[600px] border-b border-gray-100">
      <p
        onClick={() => switchFeed("pourVous")} // Mettre à jour l'état lorsque le paragraphe est cliqué
        className={`text-center w-1/2 py-1.5 font-semibold duration-100 ease-linear ${
          pubType === "pourVous" ? "bg-black text-white" : "bg-white"
        }`}
      >
        {t("pubSwitch.pourvous")}
      </p>
      <p
        onClick={() => switchFeed("tout")} // Mettre à jour l'état lorsque le paragraphe est cliqué
        className={`text-center w-1/2 py-1.5 font-semibold duration-100  ease-linear ${
          pubType === "tout" ? "bg-black text-white" : "bg-white"
        }`}
      >
        {t("pubSwitch.tout")}
      </p>
    </div>
  );
};
export default PublicationSwitcher;
