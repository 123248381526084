import React from "react";
import EditType from "../../components/common/EditType";
import { GrAddCircle } from "react-icons/gr";
import { VscDebugDisconnect } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/layouts/TopBar";
import PageSwitchCard from "../../components/common/PageSwitchCard";
import { resetUserAuthentication } from "../../store/reducer/user.reducer";
import { FaRegUserCircle } from "@react-icons/all-files/fa/FaRegUserCircle";
import { BiCategoryAlt } from "react-icons/bi";
import { MdSecurity, MdMyLocation } from "react-icons/md";
import { BiPhotoAlbum } from "@react-icons/all-files/bi/BiPhotoAlbum";
import { resetAccountConnected } from "../../store/reducer/account.reducer";
import { useTranslation } from "react-i18next";
import SwitchLangage from "../../components/common/SwitchLangage";
import { useAppSelector, useAppDispatch } from "../../store/hooks";

const EditProfileMenu: React.FC = () => {
  const { profile } = useAppSelector((state) => state.teratany_user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let accounts = useAppSelector((state) => state.teratany_account.account);

  accounts = accounts.filter((account: any) => account.id !== profile?._id);

  const userIcon = <FaRegUserCircle size={23} />;
  const profilePicture = <BiPhotoAlbum size={23} />;
  const categoryIcon = <BiCategoryAlt size={23} />;
  const passwordIcon = <MdSecurity size={23} />;
  const locationIcon = <MdMyLocation size={23} />;

  const logout = () => {
    dispatch(resetUserAuthentication());
    dispatch(resetAccountConnected());
    navigate("/signin");
  };
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="fixed top-0 z-20 pt-4 bg-white flex items-center w-full max-w-[500px]">
        <TopBar
          text={`${
            profile?.profileType === "user"
              ? t("users.setting")
              : t("pages.setting")
          }`}
        />
      </div>

      <ol className="justify-start flex-col w-full max-w-[500px] mt-12 p-2 flex items-center cursor-pointer">
        <EditType
          name={t("settings.general")}
          type="user"
          path="/profile/edit/general"
          icon={userIcon}
        />
        <EditType
          name={t("settings.picture.name")}
          type="user"
          path="/profile/edit/picture"
          icon={profilePicture}
        />
        {profile?.profileType !== "user" && (
          <EditType
            name={t("settings.category.name")}
            type="user"
            path="/profile/edit/category"
            icon={categoryIcon}
          />
        )}
        {profile?.profileType === "user" && (
          <EditType
            name={t("settings.password.name")}
            type="user"
            path="/profile/edit/password"
            icon={passwordIcon}
          />
        )}
        <EditType
          name={t("settings.location.name")}
          type="user"
          path="/profile/edit/location"
          icon={locationIcon}
        />
      </ol>
      <div className="flex flex-col items-start justify-center w-full max-w-[500px] ml-10">
        {profile?.profileType === "user" && accounts.length > 0 && (
          <div className="flex items-center justify-between mr-4 mb-2 w-[50%]">
            <p className="flex items-start mx-1 pb-1 text-m">
              {t("settings.myPages")}
            </p>
          </div>
        )}
        <div className="max-h-52 w-[100%] pr-8 overflow-y-auto">
          {accounts.map((account: any) => (
            <PageSwitchCard
              id={account.id}
              name={account.name}
              desc={
                account.followers > 0
                  ? t("followers.number.plural", {
                      followers: account.followers,
                    })
                  : t("followers.number.singular", {
                      followers: account.followers,
                    })
              }
              image={account.image}
            />
          ))}
        </div>
        {profile?.profileType === "user" && (
          <div
            className="flex items-center my-4 mx-1"
            onClick={() => {
              navigate("/page/add/step-1");
            }}
          >
            <GrAddCircle size={28} />
            <p className="px-3 ">{t("settings.addPage.name")}</p>
          </div>
        )}
        <div className="flex flex-col items-start mb-4 mx-1 w-40">
          <p className="font-semibold mx-1 mt-2 mb-3">{t("langage.name")}</p>
          <SwitchLangage />
        </div>
        <div className="flex items-center my-4 mx-1" onClick={logout}>
          <VscDebugDisconnect size={27} color="rgb(220, 38, 38, 1)" />
          <p className="px-3  text-red-600">{t("settings.disconnect")}</p>
        </div>
      </div>
    </div>
  );
};

export default EditProfileMenu;
