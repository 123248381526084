import React from "react";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "../../components/shadcn/drawer";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { Btn } from "../shadcn/button";
import { sharePublication } from "../../api/PublicationApi";
import { withAsync } from "../../helpers/withAsync";
import { toast } from "react-toastify";
import useToken from "../../hooks/useToken";
import { useAppSelector } from "../../store/hooks";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

type sharePubDrawerProps = {
  _id?: string;
  isShare?: boolean;
  originalId?: string;
};

export const SharePubDrawer: React.FC<sharePubDrawerProps> = ({
  _id,
  isShare,
  originalId,
}) => {
  const [content, setContent] = React.useState<string>("");
  const token = useToken();
  const { profile } = useAppSelector((state) => state.teratany_user);
  const { t } = useTranslation();
  const toastMessage = [t("toast.error"), t("toast.success2")];

  const handdleSharePublication = async () => {
    const { error } = await withAsync(() => {
      if (_id)
        sharePublication(
          token,
          profile?._id!,
          isShare ? originalId! : _id,
          content
        );
      else toast.error(toastMessage[0]);
    });

    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    } else {
      toast.success(toastMessage[1]);
    }
  };

  return (
    <Drawer>
      <DrawerTrigger>
        <FiSend size={24} className="mt-2" />
      </DrawerTrigger>
      <DrawerContent>
        <p className="font-semibold text-lg px-2 py-3 mb-4">
          {t("share.title")}
        </p>
        <div className="h-[20vh] w-full overflow-y-scroll mb-16 px-2">
          <textarea
            className="bg-gray-100 rounded border leading-normal resize-none w-full h-20 py-2 pl-3 font-normal placeholder-gray-700 focus:outline-none focus:bg-white"
            name="body"
            placeholder="You can add description here..."
            onChange={(e) => setContent(e.target.value)}
            value={content}
          ></textarea>
          <DrawerFooter>
            <Btn onClick={handdleSharePublication} variant="default">
              {t("share.name")}
            </Btn>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
