import TopNavBar from "../components/layouts/TopNavBar";
import Publication from "../components/publication/Publication";
import PageTopList from "../views/Page/PageTopList";
import "../styles/webResponsive.css";

import React from "react";
import { useAppSelector } from "../store/hooks";
import {
  useGetFeedFilteredPublicationQuery,
  useGetFeedPublicationQuery,
} from "../services/api-services/publication/publication.endpoints";

import { PublicationFilterDrawer } from "../components/publication/PublicationFilterDrawer";

const Home: React.FC = () => {
  const { profile } = useAppSelector((state) => state.teratany_user);
  const { categoryFilterSelected } = useAppSelector(
    (state) => state.teratany_publications
  );

  const { data: publications } = useGetFeedPublicationQuery(profile?._id!, {
    // skip the request if parameter is not exist (i.e: the request is not passing)
    skip: !profile?._id,
    //refetch on mount or arg change but not re-rendering the component if there is no new data, he
    // gives the existing cached data or gives the new data if there is an update
    refetchOnMountOrArgChange: true,
  });
  

  const ownId = profile?._id ?? ""; // Si profile._id est undefined
  const parameters = {
    ownId,
    category: categoryFilterSelected,
  };
  const { data: publicationsFiltered } = useGetFeedFilteredPublicationQuery(
    parameters,
    {
      // skip the request if parameter is not exist (i.e: the request is not passing)
      skip: !profile?._id,
      //refetch on mount or arg change but not re-rendering the component if there is no new data, he
      // gives the existing cached data or gives the new data if there is an update
      refetchOnMountOrArgChange: true,
    }
  );

  const { feedType: pubType } = useAppSelector(
    (state) => state.teratany_publications
  );

  return (
    <div className="flex flex-col items-center justify-center h-full w-full mt-12">
      <TopNavBar notifCount={9} messageCount={8} />
      {pubType === "pourVous" ? (
        <>
          <PageTopList />
          <div className="feed flex flex-col-reverse max-w-[600px]">
            {publications?.map((pub) => (
              <Publication
                key={pub?._id}
                _id={pub?._id}
                profileId={pub?.profile?._id}
                profileName={pub?.profile?.name}
                profileImage={pub?.profile?.image}
                date={pub?.date}
                comments={pub?.numberOfComments}
                reactions={pub?.numberOfReactions}
                content={pub?.content}
                images={pub?.images!}
                isReacted={pub.isReacted}
                isShare={pub.isShare}
                ownerId={pub?.owner?._id}
                ownerName={pub?.owner?.name}
                originalDate={pub?.originalDate}
                originalId={pub?.originalId}
                shareContent={pub?.shareContent}
                ownerImage={pub?.owner?.image}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mt-10 w-full max-w-[600px]">
            <PublicationFilterDrawer />
          </div>
          <div className="feed flex flex-col-reverse max-w-[600px]">
            {publicationsFiltered?.map((pub) => (
              <Publication
                key={pub?._id}
                _id={pub?._id}
                profileId={pub?.profile?._id}
                profileName={pub?.profile?.name}
                profileImage={pub?.profile?.image}
                date={pub?.date}
                comments={pub?.numberOfComments}
                reactions={pub?.numberOfReactions}
                content={pub?.content}
                images={pub?.images!}
                isReacted={pub.isReacted}
                isShare={pub.isShare}
                ownerId={pub?.owner?._id}
                ownerName={pub?.owner?.name}
                originalDate={pub?.originalDate}
                originalId={pub?.originalId}
                shareContent={pub?.shareContent}
                ownerImage={pub?.owner?.image}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
