import React from "react";
import LogoTeratany from "../../assets/Teratany.jpg";
// import { IoNotificationsOutline } from "react-icons/io5";
import { RiChat1Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { IConversation } from "../../store/reducer/chat.reducer";
import PublicationSwitcher from "./PublicationSwitcher";

interface TopNavBarProps {
  notifCount: number;
  messageCount: number;
}

const TopNavBar: React.FC<TopNavBarProps> = () => {
  const navigate = useNavigate();
  const conversations = useAppSelector(
    (state) => state.teratany_chat.discussions
  );
  return (
    <>
      <div className="fixed top-0 left-0 z-50 w-full h-12 bg-white border-b border-gray-100">
        <div className="flex items-center justify-between h-full px-2 max-w-lg mx-auto ">
          <p className=" font-serif flex justify-center ">
            <img
              loading="lazy"
              className="h-10"
              src={LogoTeratany}
              alt="instagram"
            />
          </p>
          <div className="flex items-center">
            <div className="relative px-2">
              <RiChat1Line size={26} onClick={() => navigate("/chat")} />
              {conversations.reduce(
                (acc: number, conversation: IConversation) =>
                  acc + conversation.newMessageCount,
                0
              ) > 0 && (
                <p className="absolute -top-2 right-0 w-5 h-5 text-white rounded-full flex justify-center items-center font-bold text-sm bg-red-600">
                  {conversations.reduce(
                    (acc: number, conversation: IConversation) =>
                      acc + conversation.newMessageCount,
                    0
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <PublicationSwitcher />
    </>
  );
};
export default TopNavBar;
