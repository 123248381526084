import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import useLoadingButton from "hooks/useLoadingButton";
import { withAsync } from "helpers/withAsync";
import { sendEmailRecovery } from "api/ProfileApi";
import { AxiosError } from "axios";
import { ErrorData, ThrowErrorHandler } from "helpers/HandleError";
import { useTranslation } from "react-i18next";
import teratanyLogo from "assets/Teratany_ico/apple-touch-icon-180x180.png";
import { Input } from "../../components/shadcn/input";

const ForgotPassword = () => {
  const [isLoading, startLoading, endLoading] = useLoadingButton();
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const sendMail = async () => {
    startLoading();
    const { error } = await withAsync(() => sendEmailRecovery(email!));
    if (error instanceof AxiosError) {
      endLoading();
      ThrowErrorHandler(error as ErrorData);
    } else {
      navigate("/reset-password");
    }
  };

  return (
    <div className="flex items-center h-screen bg-zinc-50">
      <main id="content" role="main" className="w-full max-w-md mx-auto p-6">
        <div className="mt-7 bg-white rounded-xl shadow-xs white:bg-gray-800 white:border-gray-700 border-2 border-gray-100">
          <div className="flex justify-center">
            <img
              loading="lazy"
              src={teratanyLogo}
              alt=""
              className=" w-20 h-20"
            />
          </div>
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-xl font-bold text-gray-800 pb-4">
                {t("authsignin.forgot")}
              </h1>
            </div>

            <div className="mt-5">
              <div className="grid gap-y-4">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("authsignin.formik.emailAddress")}
                  required
                />
                <Button
                  name={t("authsignin.formik.reset")}
                  isLoading={isLoading}
                  onClick={sendMail}
                  isDisabled={!email ? true : false}
                />

                <Button
                  name="Cancel"
                  isLoading={isLoading}
                  onClick={() => navigate("/")}
                  variant="secondary"
                />
                <p className="text-sm text-gray-600 white:text-gray-400">
                  {t("authsignin.question")}
                  <Link
                    className="text-blue-600 decoration-2 hover:underline ml-1"
                    to="/register"
                  >
                    {t("authsignin.signup")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
