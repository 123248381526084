import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  openDiscussion,
  resetNewMessageCount,
} from "../../store/reducer/chat.reducer";
import { useDispatch } from "react-redux";
import SocketContext from "../../services/socket/socketContext";
import { useAppSelector } from "../../store/hooks";
import { Avatar } from "../common/Avatar";

interface PageListCardsProps {
  name: string;
  message: string;
  newMessage?: number;
  reference: number;
  image: string;
}
const ChatList: React.FC<PageListCardsProps> = ({
  name,
  message,
  newMessage,
  reference,
  image,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);

  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const connectedUser = useAppSelector((state) => state.teratany_user.id);

  const handleOpenChat = (reference: number) => {
    socket.emit("read-message", connectedUser, conversationReference);
    dispatch(openDiscussion(reference));
    dispatch(resetNewMessageCount(reference));
  };
  return (
    <div
      className={`mx-1 w-full p-2 ${newMessage ? "bg-gray-100" : ""}`}
      onClick={() => handleOpenChat(reference)}
    >
      <div className="flex items-center">
        <div className="w-16">
          <Avatar src={image} className="w-12 h-12 !min-w-12 !min-h-12 mr-8" />
        </div>
        <div className="flex flex-col items-start w-full ml-2">
          <p className="">{name}</p>
          <p className={` text-gray-500 mb-1`}>{message}</p>
        </div>
        <div className="mr-4 flex-3">
          {newMessage ? (
            <p className="font-bold text-sm bg-red-600 rounded-full w-5 h-5 text-white flex justify-center items-center">
              {newMessage}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
