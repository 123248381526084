import React, { useEffect, useState } from "react";
import Button from "./Button";
import { withAsync } from "../../helpers/withAsync";
import { followProfile } from "../../api/ProfileApi";
import useToken from "../../hooks/useToken";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { addSearchHistory } from "../../api/SearchApi";
import { ErrorData, ThrowErrorHandler } from "../../helpers/HandleError";
import { useDispatch } from "react-redux";
import { addHistoryData } from "../../store/reducer/history.reducer";
import { IHistory } from "../../types/historique.type";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { Avatar } from "./Avatar";

interface horizontalCardsProps {
  name: string;
  desc: string;
  image?: string;
  _id?: string;
  isFollowed?: boolean;
  isButtonShowed?: boolean;
}

const HorizontalCards: React.FC<horizontalCardsProps> = ({
  name,
  desc,
  image,
  _id,
  isFollowed,
  isButtonShowed,
}) => {
  const token = useToken();
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.teratany_user);

  const [followText, setFollowText] = useState<string>(
    isFollowed! === true ? t("followers.unfollow") : t("followers.follow")
  );
  const dispatch = useDispatch();

  const follow = async () => {
    setFollowText(
      followText === t("followers.follow")
        ? t("followers.unfollow")
        : t("followers.follow")
    );
    const { error } = await withAsync(() =>
      followProfile(token, profile?._id, _id)
    );
    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    }
  };

  const addSearchResult = async (
    query: string,
    profileId: string,
    pictureUrl: string
  ) => {
    if (query.length > 4 && query.length < 20) {
      const { error, response } = await withAsync(() =>
        addSearchHistory(token, profile?._id!, query, profileId, pictureUrl)
      );
      if (error) {
        ThrowErrorHandler(error as ErrorData);
      } else {
        const historyData = response?.data as IHistory;
        dispatch(
          addHistoryData({
            owner: profile?._id!,
            text: query,
            profileId,
            pictureUrl,
            _id: historyData?._id,
          })
        );
      }
    }
  };

  useEffect(() => {}, [profile?._id, _id]);

  return (
    <div className="mx-1 w-full p-2 ">
      <div className="flex items-center">
        <Link
          to={`/profile/${_id}`}
          onClick={() => addSearchResult(name, _id!, image!)}
        >
          <Avatar src={image} className="w-12 h-12 !min-w-12 !min-h-12 mr-8" />
        </Link>
        <div className="flex flex-col items-start w-full ml-2">
          <Link
            to={`/profile/${_id}`}
            onClick={() => addSearchResult(name, _id!, image!)}
          >
            <p className="">{name}</p>
          </Link>
          <p className="text-gray-500 mb-1">{desc}</p>
        </div>
        {isButtonShowed && (
          <Button name={followText} onClick={follow} className="h-10 w-[30%]" />
        )}
      </div>
    </div>
  );
};
export default HorizontalCards;
