import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { cancelAnswear } from "../../store/reducer/chat.reducer";
import { useAppSelector } from "../../store/hooks";
import SocketContext from "../../services/socket/socketContext";

export const AnswearMessage: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();

  const [replyText, setReplyText] = useState<string>("");
  const connectedUser = useAppSelector((state) => state.teratany_user.id);
  const message: { id: string; text: string } = useAppSelector(
    (state) => state.teratany_chat.answearMessage
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplyText(e.target.value);
  };
  const isShow: boolean = useAppSelector(
    (state) => state.teratany_chat.isAnswearMessage
  );
  const conversationRef: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );

  const hide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      dispatch(cancelAnswear());
    }
  };

  const hideButton = () => {
    dispatch(cancelAnswear());
  };

  const submitAnswear = () => {
    if (socket) {
      if (replyText) {
        const myDate = Date.now();
        socket.emit(
          "reply-message",
          {
            sender: connectedUser,
            text: replyText,
            conversation: conversationRef,
            date: myDate,
          },
          message.id,
          message.text
        );

        dispatch(cancelAnswear());
      }
    }
  };

  if (!isShow) {
    return null;
  }

  return (
    <div
      onClick={hide}
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
    >
      <div className="overflow-y-auto overflow-x-hidden z-50 w-full md:inset-0 max-h-full">
        <div className="relative p-4 w-full max-w-md mx-auto">
          <div className="relative bg-white rounded-lg shadow">
            <div className="p-4 md:p-5">
              <div className="space-y-4">
                <div>
                  <p className="text-sm mb-4">
                    <b>Reply to: </b> {message.text}
                  </p>
                  <input
                    value={replyText}
                    onChange={handleChange}
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
                  />
                </div>
                {replyText ? (
                  <button
                    onClick={submitAnswear}
                    className="w-full text-white bg-black bg-opacity-80 hover:bg-opacity-100 focus:ring-4 focus:outline-none focus:ring-black  rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Send reply
                  </button>
                ) : (
                  <button
                    onClick={hideButton}
                    className="w-full text-white bg-black bg-opacity-80   rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
