/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SocketContext from "services/socket/socketContext";
import Peer from "simple-peer";
import { useAppSelector } from "store/hooks";
import Video from "views/videoChat/video";

const VideoContent = () => {
  const connectedUser = useAppSelector((state) => state.teratany_user.profile);
  const { socket } = useContext(SocketContext);
  const [peers, setPeers] = useState<Peer.Instance[]>([]);
  const userVideo = useRef<HTMLVideoElement | null>(null);
  const peersRef = useRef<Array<{ peerID: string; peer: Peer.Instance }>>([]);
  const { id: userIdToCall } = useParams();
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        setMediaStream(stream);
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
        socket.emit("join room", userIdToCall);
        socket.emit("inCommingCall", {
          userIdToCall,
          callerName: connectedUser?.name,
        });
        socket.on("all users", (users) => {
          const peers: Array<Peer.Instance> = [];
          users.forEach((userID: string) => {
            const peer = createPeer(userID, socket.id!, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push(peer);
          });
          setPeers(peers);
        });

        socket.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.calwlerID,
            peer,
          });

          setPeers((users) => [...users, peer]);
        });

        socket.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item?.peer.signal(payload.signal);
        });

        socket.on("user left", (socketID) => {
          const item = peersRef.current.find((p) => p.peerID === socketID);
          const tmp = [...peers];
          tmp.filter((peer) => peer !== item?.peer);
          setPeers(tmp);
          item?.peer.destroy();
        });
      });
  }, []);

  const createPeer = (
    userToSignal: string,
    callerID: string,
    stream: MediaStream
  ) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    setMediaStream(stream);

    peer.on("signal", (signal) => {
      socket.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  };

  const addPeer = (
    incomingSignal: Peer.SignalData,
    callerID: string,
    stream: MediaStream
  ) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    setMediaStream(stream);

    peer.on("signal", (signal) => {
      socket.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);
    return peer;
  };

  const leaveCall = () => {
    if (userVideo.current) {
      userVideo.current.srcObject = null;
      const stream = mediaStream;
      const tracks = stream?.getTracks();

      tracks && tracks[0]?.stop();

      peersRef.current.forEach((peer) => [peer.peer.destroy()]);

      setPeers([]);
      socket.emit("user left", socket.id!);
    }
    window.location.href = "/";
  };

  return (
    <div>
      <video id="localVideo" muted ref={userVideo} autoPlay playsInline></video>
      {peers.map((peer, index) => {
        return <Video key={index} peer={peer} />;
      })}
      <button
        className="fixed flex flex-col juFstify-center items-center gap-4 top-[80%] left-[50%] translate-x-[-50%] translate-y-[-50%] py-2 px-4 rounded-md bg-red-600"
        onClick={leaveCall}
      >
        Leave
      </button>
    </div>
  );
};

export default VideoContent;
