import React from "react";
import { CategorieList } from "../../constants/PageCategory";
import { CheckboxButton } from "../../components/page/CategoryCheckbox";
import TopBar from "../../components/layouts/TopBar";
import Button from "../../components/common/Button";
import { withAsync } from "../../helpers/withAsync";
import { updateCategory } from "../../api/ProfileApi";
import useToken from "../../hooks/useToken";
import useLoadingButton from "../../hooks/useLoadingButton";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../store/hooks";
import { t } from "i18next";

const ProfileCategory = () => {
  const token = useToken();

  const { profile } = useAppSelector((state) => state.teratany_user);

  let categories: string[] = [];
  const [isLoading, startLoading, endLoading] = useLoadingButton();

  const getCheckValue = (e: any, isChecked: boolean, value: string) => {
    if (!isChecked) {
      categories.push(e.target.dataset.type);
    } else {
      categories = categories.filter(
        (cat: string) => cat !== e.target.dataset.type
      );
    }
  };
  
  const updateCategories = async () => {
    startLoading();
    const { error } = await withAsync(() =>
      updateCategory(token, profile?._id, categories)
    );
    if (error instanceof AxiosError) {
      endLoading();
      const error_message: string =
      error?.response?.data.description ||
      error?.response?.data ||
      error.message;
      toast.error(error_message);
    } else {
      endLoading();
      toast.success("Category updated successfully");
    }
  };
  
  const translatedCategories = CategorieList[
    profile?.profileType === "association" ? 0 : 1
  ]
    .filter((category) => category.value !== "OTHER")
    .map((category) => ({
      ...category,
      text: t(
        `settings.categories.${category.text.toLowerCase().replace(/\s/g, "")}`
      ),
    }));
    
    return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="fixed top-0 z-20 pt-4 bg-white flex items-center w-full max-w-[500px]">
        <TopBar text={t("settings.addPage.step3.title")} />
      </div>

      <div className=" mt-16 0 flex items-center p-4 w-full flex-col max-w-[450px]">
        <div className="flex flex-wrap mb-8">
          {translatedCategories.map((category) => (
            <CheckboxButton
              value={category.value}
              onClick={getCheckValue}
              text={category.text}
            />
          ))}
        </div>
        <Button
          onClick={updateCategories}
          name="Save"
          className="w-[90%]"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ProfileCategory;
