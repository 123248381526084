import { useAppSelector } from "../../store/hooks";
import { HomeChat } from "./HomeChat";
import { OneChat } from "./OneChat";

export const ChatLayout: React.FC = ({}) => {
    const conversationReference: number = useAppSelector(
        (state) => state.teratany_chat.activeDiscussionReference
      );
  return (
    // <div className="flex">
    //   <div className="bg-red-500 flex-1">
    //     <HomeChat />
    //   </div>
    //   <div className="flex-3">
    //     {conversationReference ? <OneChat /> : <div className=""></div>}
    //   </div>
    // </div>
    <div className="flex w-screen h-screen">
      <div className=" border-e h-screen w-1/4"><HomeChat /></div>
      <div className=" w-3/4 h-screen">{conversationReference ? <OneChat /> : <div className=""></div>}</div>
    </div>
  );
};
