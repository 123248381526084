import TopBar from "../../components/layouts/TopBar";
import ChatList from "../../components/chat/ChatList";
import { useAppSelector } from "../../store/hooks";
import { switchConversationType } from "../../store/reducer/chat.reducer";
import { useDispatch } from "react-redux";
import { CreateGroupChat } from "../../components/chat/CreateGroup";

export const HomeChat: React.FC = () => {
  const { discussions: chats } = useAppSelector((state) => state.teratany_chat);
  const { conversationType: convType } = useAppSelector(
    (state) => state.teratany_chat
  );
  const switcherButtonVariants = {
    active:
      "bg-primary text-primary-foreground shadow-sm hover:bg-primary/90 text-white",
    innactive:
      "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground text-zinc-900",
  };
  const dispatch = useDispatch();
  const switchMode = (mode: string) => {
    dispatch(switchConversationType(mode));
  };
  return (
    <>
      <CreateGroupChat />
      <div className="w-full overflow-y-none flex flex-col items-center">
        <div className="flex items-center px-2 justify-center fixed bg-white border-r border-b h-14 w-1/4">
          <button
            onClick={() => switchMode("duo")}
            className={`${
              convType === "duo"
                ? switcherButtonVariants.active
                : switcherButtonVariants.innactive
            } h-8 mr-1 w-full px-3 py-2.5 rounded-md flex justify-center items-center`}
          >
            Discussions
          </button>
          <button
            onClick={() => switchMode("group")}
            className={`${
              convType === "group"
                ? switcherButtonVariants.active
                : switcherButtonVariants.innactive
            } h-8 w-full px-3 py-2.5 rounded-md flex justify-center items-center`}
          >
            Groups
          </button>
          <button
            onClick={() => switchMode("canal")}
            className={`${
              convType === "canal"
                ? switcherButtonVariants.active
                : switcherButtonVariants.innactive
            } h-8 ml-1 w-full px-3 py-2.5 rounded-md flex justify-center items-center`}
          >
            Channels
          </button>
        </div>
        <div className="mt-14 h-[calc(100vh-3.5rem)] overflow-y-auto overflow-x-hidden w-full">
          {chats
            .filter((chat: any) => {
              return chat.mode === convType;
            })
            .map((chat: any, index: number) => (
              <ChatList
                key={index}
                image={
                  chat.mode === "duo"
                    ? chat.participants[0].image
                    : chat.mode === "group"
                    ? chat.groupInfo.picture
                    : chat.channelPageOwner.image
                }
                reference={chat.reference}
                name={
                  chat.mode === "duo"
                    ? chat.participants[0].name
                    : chat.mode === "group"
                    ? chat.groupInfo.name
                    : chat.channelPageOwner.name + " channel"
                }
                message={
                  chat.messages[chat.messages.length - 1].images.length
                    ? "New images"
                    : chat.messages[chat.messages.length - 1].text !==
                      "###**###"
                    ? chat.messages[chat.messages.length - 1].text
                    : chat.mode === "duo"
                    ? "New discussion"
                    : chat.mode === "group"
                    ? "New group "
                    : "New Channel"
                }
                newMessage={chat.newMessageCount}
              />
            ))}
        </div>
      </div>
    </>
  );
};
