import { Drawer, DrawerContent, DrawerTrigger } from "../shadcn/drawer";
import { useTranslation } from "react-i18next";
import { Avatar } from "../common/Avatar";

interface FollowerListProp {
  followers: any;
  displayOrientation: "horizontal" | "vertical";
}

export const FollowersList: React.FC<FollowerListProp> = ({
  followers,
  displayOrientation,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer>
      <DrawerTrigger asChild>
        {displayOrientation === "vertical" ? (
          <div className="flex flex-col mx-4">
            <p className="text-md text-center ">{followers?.length!}</p>
            <p className="text-center">
              {followers?.length! > 1
                ? t("followers.plural")
                : t("followers.singular")}
            </p>
          </div>
        ) : (
          <div className=" mx-4">
            <span className="font-semibold">{followers?.length!} </span>
            {followers?.length! > 1
              ? t("followers.plural")
              : t("followers.singular")}
          </div>
        )}
      </DrawerTrigger>
      <DrawerContent className="max-h-[80vh]">
        <div className="p-2 pb-16 h-full overflow-y-auto">
          {followers.map((follower: any) => {
            return (
              <div className="flex items-center gap-4 mb-2 bg-gray-50 p-2 rounded-sm">
                <Avatar src={follower.image} />
                <div className="font-medium dark:text-white">
                  <div>{follower.name}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {follower.numberOfFollowers}
                    {follower.numberOfFollowers > 1
                      ? t("followers.plural")
                      : t("followers.singular")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
