export const routes = {
  home: "/",
  signin: "/signin",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  pageStepOne: "/page/add/step-1",
  pageStepTwo: "/page/add/step-2",
  pageStepThree: "/page/add/step-3",
  oneChat: "/chat/one",
  publication: "/publication/",
  editPublication: "/publication/:id",
  map: "/map",
  pagesList: "/pages/:query",
  chat: "/chat",
  search: "/search",
  searchResult: "/search/result/:query",
  notifications: "/notifications",
  searchFilterPublicationResult: "/search/result/publication/:query",
  searchFilterUserResult: "/search/result/user/:query",
  editUserMenu: "/profile/edit/menu",
  profileGeneral: "/profile/edit/general",
  profileEditPassword: "/profile/edit/password",
  profileEditPicture: "/profile/edit/picture",
  profileEditLocation: "/profile/edit/location",
  profileEditCategory: "/profile/edit/category",
  profile: "/profile/:id",
  videoChat: "/video-chat/:id",
  chatVideo: "/chat/:id"
};
