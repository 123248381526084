import React, { useEffect, useState } from "react";
import { followProfile } from "api/ProfileApi";
import useToken from "hooks/useToken";
import { withAsync } from "helpers/withAsync";
import { ErrorData, ThrowErrorHandler } from "helpers/HandleError";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { addHistoryData } from "../../../store/reducer/history.reducer";
import { Avatar } from "../../../components/common/Avatar";
import { addSearchHistory } from "../../../api/SearchApi";
import { IHistory } from "../../../types/historique.type";
import { useDispatch } from "react-redux";
import Button from "../../../components/common/Button";

interface PageListCardsProps {
  _id: string;
  name: string;
  followers: number;
  isFollowed: boolean;
  isOwner: boolean;
  image: string;
  profileType: string;
}
const PageListCard: React.FC<PageListCardsProps> = ({
  _id,
  name,
  followers,
  isFollowed,
  isOwner,
  image,
  profileType,
}) => {
  const token = useToken();
  const { t } = useTranslation();

  const [followText, setFollowText] = useState<string>("...");
  const { profile } = useAppSelector((state) => state.teratany_user);

  const follow = async () => {
    setFollowText(
      followText === t("followers.follow")
        ? t("followers.unfollow")
        : t("followers.follow")
    );
    const { error } = await withAsync(() =>
      followProfile(token, profile?._id, _id)
    );
    if (error) {
      ThrowErrorHandler(error as ErrorData);
    }
  };

  useEffect(() => {
    setFollowText(isFollowed ? t("followers.unfollow") : t("followers.follow"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFollowed]);
  const dispatch = useDispatch();

  const addSearchResult = async (
    query: string,
    profileId: string,
    pictureUrl: string
  ) => {
    if (query.length > 4 && query.length < 20) {
      const { error, response } = await withAsync(() =>
        addSearchHistory(token, profile?._id!, query, profileId, pictureUrl)
      );
      if (error) {
        ThrowErrorHandler(error as ErrorData);
      } else {
        const historyData = response?.data as IHistory;
        dispatch(
          addHistoryData({
            owner: profile?._id!,
            text: query,
            profileId,
            pictureUrl,
            _id: historyData?._id,
          })
        );
      }
    }
  };

  return (
    <div className="mx-1 w-full p-2 ">
      <div className="flex items-center">
        <Link
          to={`/profile/${_id}`}
          onClick={() => addSearchResult(name, _id!, image!)}
        >
          <Avatar src={image} className="w-12 h-12 !min-w-12 !min-h-12 mr-8" />
        </Link>
        <div className="flex flex-col items-start w-full ml-2">
          <Link
            to={`/profile/${_id}`}
            onClick={() => addSearchResult(name, _id!, image!)}
          >
            <p className="">{name}</p>
          </Link>
          <p className="text-gray-500 mb-1">
            {followers > 1
              ? t("followers.number.plural", { followers: followers })
              : t("followers.number.singular", { followers: followers })}
          </p>
        </div>
        {!isOwner && (
          <Button name={followText} onClick={follow} className="h-10 w-[30%]" />
        )}
      </div>
    </div>
  );
};

export default PageListCard;
