import {  useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { Link } from "react-router-dom";

import { MessageMenu } from "../../components/chat/MessageMenu";
import { EditMessage } from "../../components/chat/EditMessage";
import { AnswearMessage } from "../../components/chat/AnswearMessage";
import { IConversation } from "../../store/reducer/chat.reducer";
import { AddToGroup } from "./AddToGroup";
import { LeaveGroup } from "./LeaveGroupConfirmation";

type Props = {
  img: string,
  name: string,
  link: string
}

export const TopBar: React.FC<Props>  = ({img,link, name}) => {
  const connectedUser = useAppSelector((state) => state.teratany_user.id);

  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );

  const actualDiscussion = useAppSelector(
    (state) => state.teratany_chat.discussions.find(
      (element: IConversation) => element.reference === conversationReference
    )
  )

  const [participant] = useState(actualDiscussion?.participants[0]);
  const [isQuitable] = useState(
    (actualDiscussion?.mode !== "duo" &&
      !actualDiscussion?.admins?.includes(connectedUser)) ||
      actualDiscussion?.mode === "group"
  );

  return (
    <div className="">
      <div className="fixed top-0 z-40 w-3/4 h-14 border-r bg-white border-b  flex justify-between">
        <div className="flex items-center h-full w-full mx-3">
          <div className="flex w-full justify-between items-center">
            <Link
              className="flex"
              to={link}
            >
              <img
                loading="lazy"
                className="w-8 h-8 object-cover p-0.5 rounded-full ring-2 ring-gray-300 mr-2"
                src={
                 img
                }
                alt="Bordered avatar"
              />
              <p className="text-xl flex justify-center  items-center">
                {name}
              </p>
            </Link>
            <div className="flex justify-center items-center ">
              {actualDiscussion.mode === "group" && (
                <div className="mx-2 flex items-center">
                  <AddToGroup />
                </div>
              )}

              {isQuitable && (
                <div className="mx-2 flex items-center">
                  <LeaveGroup />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MessageMenu />
      <EditMessage />
      <AnswearMessage />
    </div>
  );
};
