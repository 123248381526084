import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideEditMessageInput } from "../../store/reducer/chat.reducer";
import { useAppSelector } from "../../store/hooks";
import SocketContext from "../../services/socket/socketContext";

export const EditMessage: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const message: { id: string; text: string; isMine: boolean } = useAppSelector(
    (state) => state.teratany_chat.editMessage
  );
  const [newMessage, setNewMessage] = useState<string>(message.text);

  useEffect(() => {
    setNewMessage(message.text);
  }, [message.text]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const isShow: boolean = useAppSelector(
    (state) => state.teratany_chat.isEditMessage
  );
  const conversationRef: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const dispatch = useDispatch();

  const hide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      dispatch(hideEditMessageInput());
    }
  };

  const submitEditMessage = () => {
    if (socket) {
      socket.emit("edit-message", message.id, conversationRef, newMessage);
      dispatch(hideEditMessageInput());
    }
  };

  if (!isShow) {
    return null;
  }

  return (
    <div
      onClick={hide}
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
    >
      <div className="overflow-y-auto overflow-x-hidden z-50 w-full md:inset-0 max-h-full">
        <div className="relative p-4 w-full max-w-md mx-auto">
          <div className="relative bg-white rounded-lg shadow">
            <div className="p-4 md:p-5">
              <div className="space-y-4">
                <div>
                  <input
                    value={newMessage}
                    onChange={handleChange}
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
                  />
                </div>
                {newMessage ? (
                  <button
                    onClick={submitEditMessage}
                    className="w-full text-white bg-black bg-opacity-80 hover:bg-opacity-100 focus:ring-4 focus:outline-none focus:ring-black  rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Edit
                  </button>
                ) : (
                  <button className="w-full text-white bg-black bg-opacity-80  focus:ring-4 focus:outline-none  rounded-lg focus:ring-black text-sm px-5 py-2.5 text-center">
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
