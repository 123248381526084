import { IoPersonAdd } from "react-icons/io5";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "../../components/shadcn/drawer";
import { setProfilesWithCoordonates } from "../../store/reducer/page.reducer";
import { IoPersonAddOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import profileDefault from "../../assets/userPics.jpg";
import SocketContext from "../../services/socket/socketContext";
import { withAsync } from "../../helpers/withAsync";
import useToken from "../../hooks/useToken";
import { getProfileWithCoordonates } from "../../api/ProfileApi";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FileServerURL } from "../../api/FileApi";

import { syncChat } from "../../store/reducer/chat.reducer";
import { syncChat as syncChatApi } from "../../api/chatApi";
import { useEffect, useState, useContext, useCallback } from "react";
import { IConversation, IProfile } from "../../store/reducer/chat.reducer";

export const AddToGroup: React.FC = () => {
  const connectedUser = useAppSelector((state) => state.teratany_user.id);
  const [profiles, setProfiles] = useState<IProfile[]>();
  const [profilesSearched] = useState<IProfile[]>();
  const token = useToken();
  const { socket } = useContext(SocketContext);

  const dispatch = useAppDispatch();
  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );
  const conversations = useAppSelector(
    (state) => state.teratany_chat.discussions
  );
  const actualDiscussion = conversations.find(
    (element: IConversation) => element.reference === conversationReference
  );

  const retireAlreadyInGroup = useCallback(
    (profiles: IProfile[]) => {
      const idsToRemove = actualDiscussion.participants.map(
        (item: IProfile) => item._id
      );
      setProfiles(
        profiles.filter(
          (item: IProfile) =>
            !idsToRemove.includes(item._id) && item._id !== connectedUser
        )
      );
    },
    [actualDiscussion.participants, connectedUser]
  );

  useEffect(() => {
    const fetchProfileWithCoordonates = async () => {
      const { response, error } = await withAsync(() =>
        getProfileWithCoordonates(token)
      );
      if (error instanceof AxiosError) {
        const error_message: string =
          error?.response?.data.error.description ?? error.message;
        toast.error(error_message);
        return;
      } else {
        retireAlreadyInGroup(response?.data as IProfile[]);
        dispatch(
          setProfilesWithCoordonates({ profiles: response?.data as IProfile[] })
        );
      }
    };

    fetchProfileWithCoordonates();
  }, [token, dispatch]);

  useEffect(() => {
    if (profilesSearched && profilesSearched.length > 0) {
      retireAlreadyInGroup(profilesSearched);
    }
  }, [profilesSearched, retireAlreadyInGroup]);

  const handleAddClick = (toAddId: string) => {
    if (socket) {
      socket.emit(
        "add-user-to-group",
        toAddId,
        conversationReference,
        async () => {
          dispatch(
            syncChat(
              await syncChatApi(connectedUser!.toString(), [], undefined)
            )
          );

          retireAlreadyInGroup(
            profiles!.filter((profile: IProfile) => profile._id !== toAddId)
          );
        }
      );
    }
  };

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <IoPersonAdd color="black" size={20} />
      </DrawerTrigger>
      <DrawerContent>
        <div className="p-4 pb-0">
          {/* <Input type="text" placeholder="Search..." className="mb-4" /> */}
          <div className=" h-[60vh] overflow-y-auto">
            {profiles?.map((profile: IProfile) => (
              <div className="flex my-3 justify-between items-center">
                <div className="flex items-center">
                  <img
                    loading="lazy"
                    className="w-10 h-10 object-cover p-0.5 rounded-full ring-2 ring-gray-300 mr-2 ml-1"
                    src={
                      profile?.image
                        ? FileServerURL + profile?.image
                        : profileDefault
                      // : actualDiscussion.groupInfo.picture
                    }
                    alt="Bordered avatar"
                  />
                  <p key={profile._id}>{profile.name}</p>
                </div>
                <div className="px-4 py-2 bg-gray-100 rounded-sm">
                  <IoPersonAddOutline
                    size={20}
                    onClick={() => handleAddClick(profile._id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
