import React, { useEffect, useRef } from "react";
import Peer from "simple-peer";

type VideoProps = {
    peer: Peer.Instance
}

const Video = ({ peer }: VideoProps) => {
  const ref = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    peer.on("stream", (stream) => {
      if (ref.current) {
        ref.current.srcObject = stream;
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <video id="remoteVideo" ref={ref} playsInline autoPlay></video>;
};

export default Video;
