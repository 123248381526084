import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IProfile } from "types/profile.type";
import { RootState } from "../../store/hooks";
import { showMenu } from "../../store/reducer/chat.reducer";
import { FileServerURL } from "../../api/FileApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import { SlOptionsVertical } from "react-icons/sl";
interface Props {
  id: string;
  message: string;
  date: string;
  sender: IProfile;
  replyId?: string;
  replyText?: string;
  senderName?: string;
  images: string[];
}

export const Message: React.FC<Props> = ({
  id,
  message,
  date,
  sender,
  replyText,
  replyId,
  senderName,
  images,
}) => {
  const connectedUser = useSelector<RootState>(
    (state) => state.teratany_user.id
  );

  const dispatch = useDispatch();
  const [, setIsMine] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const openMenu = (isMine: boolean) => {
    setIsMine(isMine);
    dispatch(showMenu({ id, message, isMine }));
  };

  const handleReplyClick = (replyId: string, e: any) => {
    const element = document.getElementById(replyId);
    if (element) {
      const navbarHeight = 60;
      const targetOffsetTop = element.offsetTop - navbarHeight;
      window.scrollTo({ top: targetOffsetTop, behavior: "smooth" });
      e.preventDefault();
    }
  };

  return (
    <div className="mt-2 overflow-visible">
      {message && message !== "###**###" && sender !== connectedUser && (
        <div id={id}>
          {replyId && (
            <div className="chat-message mb-[-16px] pb-3">
              <p className="ml-2 text-sm text-black">{senderName}</p>
              <div className="flex items-end">
                <div className="flex flex-col space-y-2  max-w-xs mx-2 items-start order-2">
                  <div>
                    <span
                      onClick={(e) => {
                        handleReplyClick(replyId, e);
                      }}
                      className="cursor-pointer px-4 py-2 rounded-lg inline-block  bg-gray-100 text-gray-500  text-left"
                    >
                      {replyText}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="chat-message">
            <p className="ml-2 text-sm text-black">{senderName}</p>

            <div className="flex items-end">
              <div className="flex flex-col space-y-2  max-w-xs mx-2 items-start order-2">
                <div>
                  <span
                    className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-700 text-left"
                    onClick={() => {
                      openMenu(false);
                    }}
                  >
                    {message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {message && message !== "###**###" && sender === connectedUser && (
        <div id={id}>
          {replyId && (
            <div className="chat-message mb-[-20px] pb-3">
              <div className="flex items-end justify-end flex-column">
                <div className="flex flex-col space-y-2  max-w-xs mx-2 items-end">
                  <div>
                    <span
                      onClick={(e) => {
                        handleReplyClick(replyId, e);
                      }}
                      className="cursor-pointer px-4 py-2 rounded-lg inline-block  bg-gray-100 text-gray-500  text-left"
                    >
                      {replyText}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="chat-message">
            <div className="flex items-end justify-end flex-column">
              <div className="flex flex-col space-y-2  max-w-xs mx-2 items-end">
                <div>
                  <span
                    className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-black text-white text-left"
                    onClick={() => {
                      openMenu(false);
                    }}
                  >
                    {message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {images.length > 0 && (
        <>
          {sender !== connectedUser && (
            <p className="ml-2 text-sm text-black">{senderName}</p>
          )}
          <Swiper
            effect={"cards"}
            modules={[EffectCards]}
            className={`swiper-mini w-1/2 overflow-visible h-fit ${
              sender === connectedUser ? "mr-8" : "ml-4"
            }`}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                {(() => {
                  return (
                    <img
                      loading="lazy"
                      alt=""
                      className={`rounded-xl shadow-xl object-cover max-h-[40vh] `}
                      src={FileServerURL + image}
                      onClick={() => setIsExpanded(true)}
                    />
                  );
                })()}
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
      {images && isExpanded && (
        <div
          className="swiper-fullscreen w-screen h-screen bg-zinc-600/10 fixed top-0 left-0 z-[10000] flex justify-center items-center transition-all duration-300 transform backdrop-filter backdrop-blur-md"
          onClick={() => setIsExpanded(false)}
        >
          <div
            onClick={() => {
              openMenu(false);
            }}
            className="fixed z-[10001] top-0 right-0 p-4 rounded-lg hover:bg-zinc-700/50 cursor-pointer"
          >
            <SlOptionsVertical color="black" />
          </div>
          <Swiper
            effect={"cards"}
            modules={[EffectCards]}
            className="w-8/12 overflow-visible"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                {(() => {
                  return (
                    <img
                      loading="lazy"
                      alt=""
                      className="rounded-xl shadow-xl"
                      src={FileServerURL + image}
                    />
                  );
                })()}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};
