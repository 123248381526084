import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "../../components/shadcn/drawer";
import { useState } from "react";
import { CategorieList } from "../../constants/PageCategory";
import { saveCategorySelected } from "../../store/reducer/publication.reducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { t } from "i18next";
import { CheckboxButton } from "../page/CategoryCheckbox";
import { CgOptions } from "react-icons/cg";
import { Btn } from "../shadcn/button";

export const PublicationFilterDrawer: React.FC = () => {
  const { categoryFilterSelected: catSelected } = useAppSelector(
    (state) => state.teratany_publications
  );
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(catSelected);

  const dispatch = useDispatch();
  dispatch(saveCategorySelected(selectedCategories));

  const getCheckValue = (e: any, isChecked: boolean) => {
    const categoryType = e.target.dataset.type;
    if (!isChecked) {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        categoryType,
      ]);
    } else {
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((cat) => cat !== categoryType)
      );
    }
  };

  let translatedCategories: { value: string; text: string }[] = [];

  // Boucler sur les deux index de CategorieList
  for (let i = 0; i < CategorieList.length; i++) {
    const categories = CategorieList[i];
    const translated = categories.map((category) => ({
      value: category.value,
      text: t(
        `settings.categories.${category.text.toLowerCase().replace(/\s/g, "")}`
      ),
    }));
    translatedCategories = [...translatedCategories, ...translated];
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Btn className="rounded-lg px-5 py-1 text-sm text-white mt-2 mb-1 mx-2 flex justify-center items-center">
          <CgOptions size={20} />
          <span className="ml-2">Filter</span>
        </Btn>
      </DrawerTrigger>
      <DrawerContent className="h-[90vh]">
        <div className="flex flex-wrap justify-start w-full items-center overflow-y-scroll pb-16">
          {translatedCategories.map((category, index) => (
            <CheckboxButton
              key={index}
              value={category.value}
              onClick={getCheckValue}
              text={category.text}
              isExternalChecked={catSelected.includes(category.value)}
            />
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
