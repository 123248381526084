/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { Message } from "../../components/chat/Message";
import { useAppSelector } from "../../store/hooks";
import groupDefault from "../../assets/groupPics.png";
import SocketContext from "../../services/socket/socketContext";
import { IConversation } from "../../store/reducer/chat.reducer";
import { useNavigate } from "react-router-dom";
import { IoIosSend } from "react-icons/io";
import { TopBar } from "../../components/chat/TopBar";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { FileServerURL, uploadFile } from "../../api/FileApi";
import { RiLoader3Line } from "react-icons/ri";
import { withAsync } from "../../helpers/withAsync";
import { TbPhotoShare } from "react-icons/tb";
import { IProfile } from "../../types/profile.type";
import profileDefault from 'assets/userPics.jpg';
export const OneChat: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [files, setFile] = useState<any>();
  const [showFileViewer, setViewer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [textMessage, setTextMessage] = useState<string>("");
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    t("publications.photo")
  );
  const [fileViewers, setFileViewers] = useState<string[]>([]);

  const conversationReference: number = useAppSelector(
    (state) => state.teratany_chat.activeDiscussionReference
  );

  const connectedUser = useAppSelector((state) => state.teratany_user.id);
  const connectedUserProfile = useAppSelector(
    (state) => state.teratany_user.profile
  );

  const conversations = useAppSelector(
    (state) => state.teratany_chat.discussions
  );

  const navigate = useNavigate();

  const actualDiscussion = conversations.find(
    (element: IConversation) => element.reference === conversationReference
  );

  

  const handleFileChange = (e: any) => {
    setFileViewers([]);
    setSelectedPhoto(
      `${
        e.target.files.length > 1
          ? e.target.files.length + " images "
          : e.target.files.length + " image "
      }`
    );
    for (let index = 0; index < e.target.files.length; index++) {
      setFileViewers((prevState) => [
        ...prevState,
        URL.createObjectURL(e.target.files[index]),
      ]);
    }
    if (e.target.files) {
      setViewer(true);
      setFile(e.target.files);
    }
  };

  const uploadImageFile = async () => {
    setIsLoading(true);
    if (files) {
      let formData = new FormData();
      for (let index = 0; index < files.length; index++) {
        formData.append("images", files[index]);
      }

      const { error, response } = await withAsync(() => uploadFile(formData));
      if (error instanceof AxiosError) {
        const error_message: string =
          error?.response?.data.description ||
          error?.response?.data ||
          error.message;
        toast.error(error_message);
      } else {
        return response?.data;
      }
    }
    setIsLoading(false);
  };

  const handdleMessage = async () => {
    setIsLoading(true);
    if (socket) {
      if (textMessage.trim() !== "" || files) {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView();
        }
        type Message = {
          sender?: string | null;
          text: string;
          conversation: number;
          date: number;
          images: string[];
        };

        const message: Message = {
          sender: connectedUser,
          text: textMessage,
          conversation: conversationReference,
          date: Date.now(),
          images: [],
        };

        if (files) {
          message.images = (await uploadImageFile()) as string[];
        }
        console.log(message);
        socket.emit("new-message", message);

        setTextMessage("");
        setFile(null);
        const inputElement = document.querySelector(
          'input[type="text"]'
        ) as HTMLInputElement;
        inputElement.value = ""; // Efface le contenu de l'input texte
        handleFileViewers();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  }, []);

  const emitCallUser = (participantId: string) => {
    navigate(`/chat/${participantId}`);
    socket.emit("inCommingCall", {
      userIdToCall: participantId,
      callerName: connectedUserProfile?.name,
    });
  };
  const [participant] = useState(actualDiscussion?.participants[0]);


  const handleFileViewers = () => {
    fileViewers.length = 0;
    setViewer(false);
  };

  return (
    <div className="border-r">
      <TopBar
        name={actualDiscussion.mode !== "group"
        ? participant?.name
        : actualDiscussion.groupInfo.name}
        link={`/profile/${actualDiscussion?.participants[0]?._id}`}
        img={actualDiscussion.mode !== "group"
        ? participant?.image
          ? FileServerURL + participant?.image
          : profileDefault
        : groupDefault}
      />
      <div  id="nessages" className="h-screen overflow-x-scroll">
      <div className="h-[calc(100vh-7rem)] mb-10">
        {actualDiscussion?.messages.map((message: any, index: number) => (
          <Message
            key={index}
            date={message.date?.toString() || ""}
            sender={message.sender}
            replyText={message.replyText}
            replyId={message.replyId}
            message={message.text}
            id={message._id}
            images={message.images}
            senderName={
              actualDiscussion.participants.find(
                (item: IProfile) => item._id === message.sender
              )?.name
            }
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      </div>
      <div className="fixed bg-white w-3/4 bottom-0 z-[1000] border-r">
        <form
          className="w-full"
          onSubmit={(event) => {
            event.preventDefault(); // Annule le rechargement de la page
            handdleMessage(); // Appelle la fonction de gestion du message
          }}
        >
          {(actualDiscussion?.mode !== "canal" ||
            actualDiscussion?.admins?.includes(connectedUser)) && (
            <div className="flex flex-col border-gray-300 border-t p-2">
              {showFileViewer && (
                <>
                  <p className="ml-4 py-1">{selectedPhoto}</p>
                  <svg
                    onClick={handleFileViewers}
                    className="absolute right-2 top-2 mr-1"
                    width="32px"
                    height="32px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g id="Menu / Close_SM">
                        {" "}
                        <path
                          id="Vector"
                          d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                          stroke="#000000"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                  <div className="w-full flex flex-wrap justify-start max-w-[500px] px-4 pb-1">
                    {fileViewers.map((file) => (
                      <img
                        loading="lazy"
                        className="w-10 h-10 rounded-md mt-2 mr-2 object-cover  max-w-[500px]"
                        src={file}
                        alt="selectedPhoto"
                      />
                    ))}
                  </div>
                </>
              )}
              <div className="relative w-full gap-x-2 flex">
                <input
                  type="text"
                  className="block p-2.5 w-[90%] z-20 text-gray-900 rounded-lg border border-1"
                  placeholder="Message"
                  onChange={(v) => setTextMessage(v.target.value)}
                  value={textMessage}
                />

                <label
                  htmlFor="image"
                  className="flex justify-start items-center cursor-pointer"
                >
                  <TbPhotoShare size={35} color="black" />
                </label>
                <input
                  type="file"
                  id="image"
                  className="hidden  max-w-[500px]"
                  onChange={handleFileChange}
                  multiple
                />

                {!isLoading && (
                  <button
                    className="bg-gray-900 p-2 w-11 h-w-10 text-white rounded-md"
                    type="submit"
                  >
                    <IoIosSend size={25} />
                  </button>
                )}
                {isLoading && (
                  <button className="bg-gray-900 p-2 w-11 h-w-10 text-white rounded-md flex items-center justify-center">
                    <RiLoader3Line className="animate-spin" size={25} />
                  </button>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
