import React from "react";
import { FileServerURL } from "../../api/FileApi";
import profileDefault from "../../assets/userPics.jpg";

interface AvatarProps {
  src: any;
  ringColor?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  ringColor,
  className,
}) => {
  return (
    <img
      className={`w-10 h-10 p-1 rounded-full ring-2 ring-${
        ringColor ? `[${ringColor}]` : "gray-300"
      } object-cover ${className ? className : ""}`}
      src={src ? FileServerURL + src : profileDefault}
      alt=""
    />
  );
};
