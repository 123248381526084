import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import { useEffect, useState } from "react";
import { CommentDrawer } from "../drawer/CommentDrawer";
import { FileServerURL } from "../../api/FileApi";
import { ConvertDate } from "../../helpers/DateConverter";
import { withAsync } from "../../helpers/withAsync";
import { toggleReactPublication } from "../../api/PublicationApi";
import useToken from "../../hooks/useToken";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { MenuPublication } from "../../views/Publication/components/MenuPublication";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { sharePublication } from "api/PublicationApi";

import "swiper/css";
import "swiper/css/pagination";
import "../../styles/SwiperPublication.css";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { Card } from "../shadcn/card";
import { Avatar } from "../common/Avatar";
import { SharePubDrawer } from "./SharePubDrawer";

interface PublicationProps {
  _id?: string;
  profileId?: string;
  profileName?: string;
  date?: string;
  profileImage?: string;
  images?: string[];
  content?: string;
  reactions?: number;
  comments?: number;
  isReacted?: boolean;
  isShare?: boolean;
  originalId?: string;
  ownerId?: string;
  ownerName?: string;
  ownerImage?: string;
  shareContent?: string;
  originalDate?: string;
}

const Publication: React.FC<PublicationProps> = ({
  _id,
  profileId,
  profileName,
  date,
  profileImage,
  images,
  content,
  reactions,
  comments,
  isReacted,
  isShare,
  originalId,
  ownerId,
  ownerName,
  originalDate,
  shareContent,
  ownerImage,
}) => {
  const [isPostLiked, setIsPostLiked] = useState<Boolean>(isReacted!);
  const [isFullContent, setIsFullContent] = useState<boolean>(false);
  const [isFullShareContent, setIsFullShareContent] = useState<boolean>(false);
  const [react, setReact] = useState<number>(reactions!);
  const token = useToken();
  const { profile } = useAppSelector((state) => state.teratany_user);
  const [, setFormattedDate] = useState<string>("");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
    setFormattedDate(moment(date).fromNow());
  }, [date, i18n.language]);

  const handleClickLikePost = async (_id: string) => {
    setIsPostLiked(!isPostLiked);
    if (isPostLiked) {
      setReact((react) => react - 1);
    } else {
      setReact((react) => react + 1);
    }

    const { error } = await withAsync(() =>
      toggleReactPublication(token, profile?._id!, _id)
    );
    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    }
  };

  const togglePubContentDetails = () => {
    setIsFullContent(!isFullContent);
  };
  const togglePubShareContentDetails = () => {
    setIsFullShareContent(!isFullShareContent);
  };

  return (
    // <!-- Wrapper-->
    <Card className="mx-2 my-1">
      <div className="wrapper my-2 w-full flex flex-col items-center bg-white rounded-lg">
        {/* <!-- Card--> */}
        {isShare && (
          <div className="flex flex-col border-b-2 border-gray-200 w-full max-w-[600px] overflow-auto pt-2">
            <div className="flex items-start w-full h-auto px-4 text-sm justify-between pb-4">
              <Link
                className="flex items-center"
                to={`/profile/${isShare ? ownerId : profileId}`}
              >
                <Avatar
                  src={isShare ? profileImage : ownerImage}
                  className="w-[3rem] h-[3rem]  mr-4 "
                />

                <div className="flex flex-col">
                  <div>
                    <p className="flex text-base font-bold white:text-white">
                      {isShare ? profileName : ownerName}
                    </p>
                  </div>
                  <div className="flex text-sm text-slate-500 white:text-slate-300 white:text-slate-400">
                    {ConvertDate(isShare ? date! : originalDate!)}
                  </div>
                </div>
              </Link>
              {isShare && profileId === profile?._id && (
                <div className="">
                  <MenuPublication id={_id!} />
                </div>
              )}
            </div>
            <div className="mx-4">
              <p
                className={`white:text-slate-200 text-start break-words overflow-hidden ${
                  !isFullShareContent
                    ? `truncated-text ${
                        shareContent?.length! > 0 ? `h-[30px]` : `" "`
                      } `
                    : "h-full"
                } `}
              >
                {shareContent}
              </p>
              {!isFullShareContent && shareContent?.length! > 80 && (
                <p
                  className="text-left  text-gray-400 font-normal mb-2"
                  onClick={togglePubShareContentDetails}
                >
                  {t("publications.plus")}
                </p>
              )}
              {isFullShareContent && (
                <p
                  className="text-left  text-gray-400 font-normal mb-2"
                  onClick={togglePubShareContentDetails}
                >
                  moins
                </p>
              )}
            </div>
          </div>
        )}
        <article
          className={`mb-4 break-inside rounded-xl bg-white white:bg-slate-800 flex flex-col bg-clip-border w-full ${
            isShare ? "px-4" : ""
          }`}
        >
          <div
            className={
              images?.length! > 0
                ? "flex p-4 items-center justify-between"
                : "flex px-4 pt-4 pb-0 items-center justify-between"
            }
          >
            <div className="flex">
              <Link
                className="flex items-center"
                to={`/profile/${isShare ? ownerId : profileId}`}
              >
                <Avatar
                  src={isShare ? ownerImage : profileImage}
                  className={`mr-4 ${
                    isShare ? "w-[2rem] h-[2rem] " : "w-[3rem] h-[3rem]"
                  }`}
                />

                <div className="flex flex-col">
                  <div>
                    <p
                      className={`flex font-bold white:text-white ${
                        isShare ? "text-sm" : "text-base"
                      }`}
                    >
                      {isShare ? ownerName : profileName}
                    </p>
                  </div>
                  <div
                    className={`flex text-slate-500 white:text-slate-300 white:text-slate-400 ${
                      isShare ? "text-xs" : "text-sm"
                    }`}
                  >
                    {ConvertDate(isShare ? originalDate! : date!)}
                  </div>
                </div>
              </Link>
            </div>
            {!isShare && profileId === profile?._id && (
              <MenuPublication id={_id!} />
            )}
          </div>
          <div className="z-1">
            {images && (
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {images.length > 1 ? (
                  images.map((image, index) => (
                    <SwiperSlide key={index}>
                      {(() => {
                        const tempImage = new Image();
                        tempImage.src = FileServerURL + image;
                        const minHeight = 600;
                        const isImageTooLarge = tempImage.height > minHeight;

                        return (
                          <>
                            {!isImageTooLarge ? (
                              <div className="swiper-slide">
                                <div className="bg-image"></div>
                                <img
                                  loading="lazy"
                                  alt=""
                                  className="w-full h-full object-contain"
                                  src={FileServerURL + image}
                                />
                              </div>
                            ) : (
                              <div className="swiper-slide">
                                <img
                                  loading="lazy"
                                  alt=""
                                  className="w-full h-full object-center object-cover scale-[100%]"
                                  src={FileServerURL + image}
                                />
                              </div>
                            )}
                          </>
                        );
                      })()}
                    </SwiperSlide>
                  ))
                ) : (
                  <>
                    {images.length === 1 ? (
                      <img
                        loading="lazy"
                        alt=""
                        className="w-full h-full object-center scale-[100%]"
                        src={FileServerURL + images}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Swiper>
            )}
          </div>
          <div className="p-4 pb-0">
            <div
              className={
                images?.length! > 0 ? "flex flex-col" : "flex flex-col-reverse"
              }
            >
              <div className="flex flex-col justify-start">
                <div className="inline-flex items-center">
                  <span className="mr-4">
                    {isPostLiked ? (
                      <AiFillHeart
                        onClick={() => handleClickLikePost(_id!)}
                        size={30}
                        color="#FF3040"
                        className="active:scale-75 transition-transform cursor-pointer"
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={() => handleClickLikePost(_id!)}
                        size={30}
                        className="active:scale-75 transition-transform cursor-pointer"
                      />
                    )}
                  </span>
                  <span className="mr-4">
                    <CommentDrawer _id={_id} />
                  </span>
                  <span className="mr-4">
                    <SharePubDrawer
                      _id={_id}
                      isShare={isShare}
                      originalId={originalId}
                    />
                  </span>
                </div>
                <p className="text-left py-2 text-gray-800 ">
                  {react > 1
                    ? t("publications.likes.plural", { count: react })
                    : t("publications.likes.singular", { count: react })}
                </p>
              </div>
              <div className="text-container">
                <p
                  className={
                    images?.length! > 0
                      ? `white:text-slate-200 text-start break-words ${
                          !isFullContent ? "truncated-text" : ""
                        } `
                      : `white:text-slate-200 mb-2 ${
                          !isFullContent ? "truncated-text" : ""
                        } `
                  }
                >
                  {content}
                </p>
                {!isFullContent && content?.length! > 150 && (
                  <p
                    className="text-left  text-gray-400 font-normal mb-2"
                    onClick={togglePubContentDetails}
                  >
                    {t("publications.plus")}
                  </p>
                )}
              </div>
            </div>

            {comments! > 0 && (
              <p className="text-left  text-gray-400 font-normal">
                {t("publications.showComments", { comments: comments })}
              </p>
            )}
            <p className="text-left text-xs text-gray-400 font-normal">
              {moment(date).startOf("second").fromNow()}
            </p>
          </div>
        </article>
      </div>
    </Card>
  );
};

export default Publication;
