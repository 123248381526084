import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const axiosParams = {
  baseURL: process.env.REACT_APP_BASE_URL_FILE,
};
const api = (axios: AxiosInstance) => {
  return {
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.post<T>(url, body, config),
  };
};
const axiosInstance = axios.create(axiosParams);
const URLS = { uploadFile: "/" };
export const uploadFile = (images: any) => {
  return api(axiosInstance).post(URLS.uploadFile, images);
};

export const FileServerURL = process.env.REACT_APP_BASE_URL_FILE + "/public/";
