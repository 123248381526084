import { useTranslation } from "react-i18next";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "../../components/shadcn/drawer";
import Comments from "../../views/Comments/Comments";
import { FaRegComment } from "@react-icons/all-files/fa/FaRegComment";
import { withAsync } from "../../helpers/withAsync";
import { deleteComment, getComments, postComment } from "../../api/CommentApi";
import React, { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import useToken from "../../hooks/useToken";
import { IComment } from "../../types/comment.type";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import {
  Avatar,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import moment from "moment";
import { Btn } from "../shadcn/button";

type CommentDrawerrProps = {
  _id?: string;
};
export const CommentDrawer: React.FC<CommentDrawerrProps> = ({ _id }) => {
  const token = useToken();
  const [content, setContent] = React.useState<string>();
  const [comments, setComments] = React.useState<IComment[]>();
  const { profile } = useAppSelector((state) => state.teratany_user);

  const addComment = async () => {
    const { error } = await withAsync(() =>
      postComment(token, profile?._id, content, _id)
    );
    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    } else {
      const newCom = {
        content,
        date: Date.now(),
        profile: {
          name: profile?.name,
          image: profile?.image,
        },
      };
      setComments((com: any) => [...com, newCom]);
      setContent("");
    }
  };

  const removeComment = async (commentId: string) => {
    const { error } = await withAsync(() => deleteComment(token, commentId));
    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    } else {
      window.location.reload();
      setTimeout(() => {
        toast.success("Comment removed");
      }, 1000);
    }
  };

  const fetchComments = async () => {
    const { error, response } = await withAsync(() => getComments(token, _id));
    if (error instanceof AxiosError) {
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    } else {
      setComments(response?.data as Array<IComment>);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);
  return (
    <Drawer>
      <DrawerTrigger>
        <FaRegComment size={24} className="mt-2"/>
      </DrawerTrigger>
      <DrawerContent>
        <p className="font-semibold text-lg px-2 py-3">Comments</p>
        <div className="h-[50vh] overflow-y-scroll mb-16">
          {comments?.map((comment) => (
            <div className="flex flex-col rounded-md p-3 border my-1 ">
              <div className="flex gap-3 items-center">
                <Link
                  className="flex gap-3 items-center"
                  to={`/profile/${comment?.profile?._id}`}
                >
                  <Avatar src={comment?.profile?.image} />
                  <h3 className="font-bold">{comment?.profile?.name}</h3>
                </Link>
              </div>

              <p className="flex text-left text-gray-600 mt-2">
                {comment.content}
              </p>
              <div className="flex">
                <p className="text-left text-xs text-gray-400 font-normal mr-2">
                  {moment(comment.date).fromNow()}
                </p>

                {comment?.profile?._id === profile?._id && (
                  <Popover
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0, y: 25 },
                    }}
                  >
                    <PopoverHandler>
                      <p className="text-left text-xs hover:underline font-normal text-red-500 cursor-pointer">
                        {t("comments.delete")}
                      </p>
                    </PopoverHandler>
                    <PopoverContent
                      placeholder=""
                      className="ml-4 shadow-2xl"
                    >
                      {t("comments.deleteConfirm")}
                      <div className="flex">
                        <p
                          className="text-left text-xs hover:underline font-normal text-red-500 cursor-pointer"
                          onClick={() => removeComment(comment?._id!)}
                        >
                          {t("comments.deleteValidate")}
                        </p>
                      </div>
                    </PopoverContent>
                  </Popover>
                )}
              </div>
            </div>
          ))}
          <textarea
            className="bg-gray-100 rounded border leading-normal resize-none w-full h-20 py-2 pl-3 font-normal placeholder-gray-700 focus:outline-none focus:bg-white"
            name="body"
            placeholder={t("comments.yourComment")}
            onChange={(e) => setContent(e.target.value)}
            value={content}
          ></textarea>
          <DrawerFooter>
            <Btn onClick={addComment} variant="default">
              {t("comments.postComment")}
            </Btn>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
