import React, { useContext } from "react";
import { FileServerURL } from "../../api/FileApi";
import Button from "../../components/common/Button";
import { IProfile } from "../../types/profile.type";
import pictureDefault from "../../assets/userPics.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { syncChat as syncChatApi } from "../../api/chatApi";
import { openDiscussion, syncChat } from "../../store/reducer/chat.reducer";
import { useAppSelector } from "../../store/hooks";
import SocketContext from "../../services/socket/socketContext";
import { FollowersList } from "../../components/drawer/FollowersListDrawer";

interface PageProfileProps {
  profile: IProfile;
  followText: string;
  follow: () => void;
  changeDrawerStatus: () => void;
}

const sdgs = [
  "ODD1",
  "ODD2",
  "ODD3",
  "ODD4",
  "ODD5",
  "ODD6",
  "ODD7",
  "ODD8",
  "ODD9",
  "ODD10",
  "ODD11",
  "ODD12",
  "ODD13",
  "ODD14",
  "ODD15",
  "ODD16",
  "ODD17",
];

const csrs = [
  "RSE1",
  "RSE2",
  "RSE3",
  "RSE4",
  "RSE5",
  "RSE6",
  "RSE7",
  "RSE8",
  "RSE9",
  "RSE10",
  "RSE11",
  "RSE12",
  "RSE13",
  "RSE14",
  "RSE15",
  "RSE16",
];
export const PageProfile: React.FC<PageProfileProps> = ({
  profile,
  followText,
  follow,
  changeDrawerStatus,
}) => {
  const { socket } = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectedUser = useAppSelector((state) => state.teratany_user.id);
  const handdleMessage = () => {
    if (socket) {
      socket.emit(
        "new-conversation",
        [connectedUser, profile._id],
        async (response: number) => {
          dispatch(syncChat(await syncChatApi(connectedUser!, [], undefined)));
          navigate("/chat/one");
          dispatch(openDiscussion(response));
        }
      );
    }
  };

  const handdleChannel = () => {
    if (socket) {
      socket.emit(
        "join-channel",
        connectedUser,
        profile._id,
        async (response: number) => {
          dispatch(syncChat(await syncChatApi(connectedUser!, [], undefined)));
          navigate("/chat/one");
          dispatch(openDiscussion(response));
        }
      );
    }
  };
  return (
    <div className="flex flex-col w-full max-w-[600px] mt-16 pb-6 border-b border-gray-200">
      <div className="flex items-start w-auto justify-around mx-2">
        <div className="flex flex-col items-center">
          <img
            loading="lazy"
            className="w-14 h-14 object-cover rounded-full  border-2 border-pink-600"
            src={
              profile?.image ? FileServerURL + profile.image : pictureDefault
            }
            alt="profile"
          />
          <p className="text-md mb-3">{profile?.name}</p>
        </div>
        <div className="flex flex-col items-end ">
          <div className="flex">
            <div className="flex flex-col ">
              <p className="text-md text-center ">
                {profile?.publications?.length!}
              </p>
              <p className="text-center">
                {profile?.publications?.length! > 1
                  ? t("posts.plural")
                  : t("posts.singular")}
              </p>
            </div>
            <FollowersList
              followers={profile.followers}
              displayOrientation="vertical"
            />
            <div className="flex flex-col ">
              <p className="text-md text-center ">
                {profile?.localisation?.country?.value}
              </p>
              <p className="text-center">{t("profile.location")}</p>
            </div>
          </div>
          <div className="flex  items-center justify-evenly my-3">
            <div className="flex flex-wrap w-full">
              {profile.profileType === "association"
                ? sdgs.map((sdg: string) => {
                    if (profile.categories?.includes(sdg)) {
                      return (
                        <div
                          className={`w-2 h-2 bg-sdg-${sdg} rounded-full mr-1 mb-1`}
                        ></div>
                      );
                    } else {
                      return (
                        <div className="w-2 h-2 bg-gray-300 rounded-full mr-1 mb-1"></div>
                      );
                    }
                  })
                : csrs.map((csr: string) => {
                    if (profile.categories?.includes(csr)) {
                      return (
                        <div className="w-2 h-2 bg-black rounded-full mr-1 mb-1"></div>
                      );
                    } else {
                      return (
                        <div className="w-2 h-2 bg-gray-300 rounded-full mr-1 mb-1"></div>
                      );
                    }
                  })}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center w-auto m-2">
        <Button className="w-full h-9" name={followText} onClick={follow} />
      </div>
      <div className="flex items-center justify-center w-auto mx-2 gap-x-2">
        <Button
          className="w-1/2 h-9"
          name={t("profile.message")}
          onClick={handdleMessage}
        />
        <Button
          className="w-1/2 h-9"
          name={t("chat.channel")}
          onClick={handdleChannel}
        />
        <Button className="w-1/6 h-9" name="•••" onClick={changeDrawerStatus} />
      </div>
    </div>
  );
};

export default PageProfile;
