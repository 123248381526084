/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SocketContext from "services/socket/socketContext";
import Peer from "simple-peer";

const NewVideo = () => {
  const localVideo = useRef<HTMLVideoElement | null>(null);
  const remoteVideo = useRef<HTMLVideoElement | null>(null);
  const { socket } = useContext(SocketContext);
  const [statusDiv, setStatusDiv] = useState<string>("");
  const { id } = useParams();
  useEffect(() => {
    let peer: Peer.Instance;
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        if (localVideo.current) {
          localVideo.current.srcObject = stream;
        }
        socket.emit("join", id);
        socket.on("other-user", (otherUserId) => {
          peer = new Peer({ initiator: true, trickle: false, stream });
          peer.on("signal", (signal) => socket.emit("offer", signal));
          peer.on("stream", (stream) => {
            if (remoteVideo.current) {
              remoteVideo.current.srcObject = stream;
              setStatusDiv("");
            }
          });
          socket.on("answer", (answer) => peer.signal(answer));
        });

        socket.on("offer", (offer) => {
          peer = new Peer({ initiator: false, trickle: false, stream });
          peer.on("signal", (signal) => socket.emit("answer", signal));
          peer.on("stream", (stream) => {
            if (remoteVideo.current) {
              remoteVideo.current.srcObject = stream;
              setStatusDiv("");
            }
          });
          peer.signal(offer);
        });

        socket.on("user-disconnected", () => {
          setStatusDiv("Your friend has left the call.");
        });
      })
      .catch((error) => {
        console.error("Error accessing media devices: ", error);
      });
  }, []);

  
  const leaveCall = () => {
    window.location.href = '/'
  };


  return (
    <div>
      <h1 id="status">{statusDiv}</h1>
      <video
        id="localVideo"
        ref={localVideo}
        autoPlay
        playsInline
        muted
      ></video>
      <video id="remoteVideo" ref={remoteVideo} autoPlay playsInline></video>
      <button
        className="fixed flex flex-col juFstify-center items-center gap-4 top-[80%] left-[50%] translate-x-[-50%] translate-y-[-50%] py-2 px-4 rounded-md bg-red-600"
        onClick={leaveCall}
      >
        Leave
      </button>
    </div>
  );
};

export default NewVideo;
