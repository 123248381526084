import React, { useState } from "react";
import SwitchToggle from "../../components/common/switchToggle";
import Button from "../../components/common/Button";
import TopBar from "../../components/layouts/TopBar";
import MapContainerForm from "../../components/common/MapContainer";
import { Marker, useMapEvents } from "react-leaflet";
import { MARKER_USER } from "../../constants/MarkerIcon";
import { withAsync } from "../../helpers/withAsync";
import { updateLocationParameter } from "../../api/ProfileApi";
import useToken from "../../hooks/useToken";
import useLoadingButton from "../../hooks/useLoadingButton";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";

type PositionMarkerType = {
  lat: number;
  lng: number;
};

const LocationMarker = () => {
  const [position, setPosition] = useState<PositionMarkerType | null>(null);
  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      localStorage.setItem("lat", String(e.latlng.lat));
      localStorage.setItem("lng", String(e.latlng.lng));
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={MARKER_USER}></Marker>
  );
};

const ProfileLocation: React.FC = () => {
  const token = useToken();
  const { profile } = useAppSelector((state) => state.teratany_user);

  const [isLoading, startLoading, endLoading] = useLoadingButton();
  const [locationStatus, setLocationStatus] = useState<boolean>();
  const { t } = useTranslation();

  const changeLocationStatus = (status: any) => {
    setLocationStatus(status.target.checked);
  };

  const updateLocation = async () => {
    const lat: number = Number(localStorage.getItem("lat"));
    const lng: number = Number(localStorage.getItem("lng"));
    startLoading();
    const { error } = await withAsync(() =>
      updateLocationParameter(token, profile?._id, lat, lng, locationStatus!)
    );
    if (error instanceof AxiosError) {
      endLoading();
      const error_message: string =
        error?.response?.data.description ||
        error?.response?.data ||
        error.message;
      toast.error(error_message);
    } else {
      endLoading();
      const successToast = t("settings.location.successLocation");
      toast.success(successToast);
      localStorage.removeItem("lat");
      localStorage.removeItem("lng");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="fixed top-0 z-4000 bg-white pt-4 flex items-center w-full max-w-[500px]">
        <TopBar text={t("settings.location.name")} />
      </div>

      {profile && (
        <MapContainerForm
          lat={profile?.localisation?.coordonates?.latitude! as number}
          lng={profile?.localisation?.coordonates?.longitude! as number}
          className="w-full h-[400px] mt-14 mx-auto flex justify-center items-center"
        >
          <Marker
            position={[
              profile?.localisation?.coordonates?.latitude!,
              profile?.localisation?.coordonates?.longitude!,
            ]}
            icon={MARKER_USER}
          ></Marker>
          <LocationMarker />
        </MapContainerForm>
      )}

      <div className="mt-4 p-4 flex flex-col items-center mx-4 w-full max-w-[450px]">
        <p className="">{t("settings.location.locationDesc")}</p>
        <div className="flex items-start mt-4">
          <SwitchToggle
            label={t("settings.location.switch")}
            isChecked={profile?.localisation?.coordonates?.isPublic}
            onClick={changeLocationStatus}
          />
        </div>
        <div className="mt-10 w-full">
          <Button
            name={t("settings.save")}
            isLoading={isLoading}
            onClick={updateLocation}
          />
        </div>
      </div>
    </div>
  );
};
export default ProfileLocation;
