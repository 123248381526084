import { AiOutlineUsergroupAdd } from "react-icons/ai";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../components/shadcn/drawer";
import { Btn } from "../../components/shadcn/button";
import SocketContext from "../../services/socket/socketContext";
import { Input } from "../shadcn/input";
import { useAppSelector } from "../../store/hooks";
import { syncChat } from "../../store/reducer/chat.reducer";
import { syncChat as syncChatApi } from "../../api/chatApi";

import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
export const CreateGroupChat: React.FC = () => {
  const { socket } = useContext(SocketContext);
  const [name, setName] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    setName("");
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const connectedUser = useAppSelector((state) => state.teratany_user.id);

  const submitEditMessage = () => {
    if (name.length) {
      if (socket) {
        socket.emit("create-group", name, connectedUser, async () => {
          setName("");
          dispatch(
            syncChat(
              await syncChatApi(connectedUser!.toString(), [], undefined)
            )
          );
        });
      }
    }
  };

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <div className="bg-primary w-10 h-10 flex justify-center items-center rounded-md fixed bottom-2 left-2">
          <AiOutlineUsergroupAdd color="white" size={24} />
        </div>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader>
            <DrawerTitle>Create new group</DrawerTitle>
            <DrawerDescription>
              Find a name for your group then add your mates
            </DrawerDescription>
          </DrawerHeader>
          <div className="p-4 pb-0">
            <Input
              type="text"
              placeholder="Your group name"
              onChange={handleChange}
              value={name}
            />
          </div>
          <DrawerFooter>
            {!name.length ? (
              <Btn variant={name.length ? "default" : "disabled"}>Submit</Btn>
            ) : (
              <DrawerClose asChild>
                <Btn
                  onClick={submitEditMessage}
                  variant={name.length ? "default" : "disabled"}
                >
                  Submit
                </Btn>
              </DrawerClose>
            )}

            <DrawerClose asChild>
              <Btn variant="outline" className="mb-16">
                Cancel
              </Btn>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
